export const No = () => {
  return (
    <>
      <section className="no section--with-anim" id="no">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 m-auto col-xl-4 col--small">
              <h2 className="no__title">
                {/* N&deg;7 <br /> Słowackiego */}
                <div
                  className="no__title-logo"
                  style={{
                    backgroundImage: `url(${
                      require("../assets/images/icons/logo-s7-pelne.svg")
                        .default
                    })`,
                  }}
                ></div>
              </h2>
            </div>
            <div className="col-12 col-md-8 m-auto col-xl-8 col--small">
              <div className="app__text-box no__text-box">
                <div className="line"></div>
                <p className="no__text">
                  To adres w centrum miasta, który gwarantuje:
                </p>
                <ul className="no__list list-unstyled">
                  <li className="no__list-item">
                    - rosnącą wartość nieruchomości
                  </li>
                  <li className="no__list-item">
                    - liczne tereny zielne, parki, bliskość zoo, 10 minut
                    do&nbsp;Starego&nbsp;Rynku
                  </li>
                  <li className="no__list-item">
                    - dostepność szkół i przedszkoli oraz uczelni to zalety
                    mieszkania w centrum miasta zarówno dla rodziców z uwagi na
                    oszczędność czasu, jak i dla dzieci z uwagi na ich
                    bezpieczeństwo
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default No;
