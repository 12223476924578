export const ZommedImage = () => {
   return (
      <>
         <div className="zoomed-image" hidden={true}>
            <div
               className="zoomed-image__bg"
               onClick={(e) => {
                  e.target.parentNode.setAttribute("hidden", true);
                  document.body.style = "";
               }}
            ></div>
            <div className="zoomed-image__box"></div>
         </div>
      </>
   );
};

export default ZommedImage;
