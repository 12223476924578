import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import FormAsk from "../components/FormAsk";

export const OfferItem = (props) => {
  const [menu, setMenu] = useState(false);

  const [flat, setFlat] = useState(null);

  const [form, setForm] = useState(false);

  let { offerId } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    props.reset();
    window.scrollTo(0, 0);
    return () => {
      props.appLoadFun();
    };
  }, [props]);

  const toggleMenu = () => {
    !menu
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style = "");
    setMenu(!menu);
  };

  const filterFlatLoad = () => {
    let flt;

    flt = props.data.filter((item) => {
      if (item.id === Number(offerId)) {
        return item;
      } else return null;
    });

    setFlat(flt[0]);
  };

  const setStatus = (status) => {
    switch (status) {
      case "available":
        return "wolne";

      case "reservation":
        return "zarezerwowane";

      case "preReservation":
        return "zarezerwowane";

      case "sold":
        return "sprzedane";

      default:
        break;
    }
  };

  if (props.data && !flat) filterFlatLoad();

  const toggleForm = (e) => {
    if (e.target.closest(".form-box") === null) {
      !form
        ? (document.body.style.overflowY = "hidden")
        : (document.body.style = "");
      setForm(!form);
    }
  };

  return (
    <>
      <header className="header header--offer d-xl-flex">
        <div className="header__left-col" hidden-xl="true">
          <div className="header__menu">
            <div className={`header__menu-list${menu ? " active" : ""}`}>
              <span className="header__menu-list-close" onClick={toggleMenu}>
                X
              </span>
              <ul className="list-unstyled">
                <li>
                  <NavLink to="/">Strona główna</NavLink>
                </li>
                <li>
                  <NavLink to="/oferta">Oferta</NavLink>
                </li>
                <li onClick={(e) => toggleForm(e)}>Kontakt</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="header__right-col">
          <div
            className="header__top"
            style={{
              backgroundImage: `url(${require("../assets/images/texture.jpg")})`,
            }}
          >
            <div
              className="header__top-mobile-menu-logo"
              style={{
                backgroundImage: `url(${
                  require("../assets/images/icons/logo-s7.svg").default
                })`,
              }}
            ></div>
            <div className="header__top-menu d-none d-xl-flex">
              <ul>
                <li>
                  <NavLink to="/">Strona główna</NavLink>
                </li>
                <li>
                  <NavLink to="/oferta">Oferta</NavLink>
                </li>
                <li onClick={(e) => toggleForm(e)}>Kontakt</li>
              </ul>
              <div>
                Biuro sprzedaży:
                <b>61 671 18 05</b>
              </div>
            </div>
            <div className="header__top-mobile-menu-icon" onClick={toggleMenu}>
              <span></span>
            </div>
          </div>
        </div>
      </header>
      {flat ? (
        <section className="offer-details">
          <div className="offer-details__item">
            <div className="offer-details__back">
              <button onClick={() => navigate(-1)}>
                <b>&#8592;</b> powrót
              </button>
            </div>
            <div className="offer-details__item-data" hidden-xl="true">
              <div className="offer-details__item-data-title">
                {flat.number}
                <b>{setStatus(flat.state)}</b>
              </div>
              {flat.state !== "sold" && (
                <div className="offer-details__item-data-price">
                  <div className="offer-details__item-data-price-full">
                    Cena
                    <b>
                      {flat.priceOffer.brutto
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      &nbsp;zł
                    </b>
                  </div>
                  <div className="offer-details__item-data-price-area">
                    Cena za m<sup>2</sup>:
                    <b>
                      {flat.priceOffer.areaBrutto
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      &nbsp;zł
                    </b>
                  </div>
                  {(flat.number === "U5" || flat.number === "U8") && (
                    <div className="offer-details__item-data-price-area red">
                      Cena promocyjna:{" "}
                      <b>
                        11 500 zł/m<sup>2</sup> netto
                      </b>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              className="offer-details__item-image"
              style={{
                backgroundImage: `url(${require(`../assets/images/flats/${flat.id}.png`)})`,
              }}
            ></div>

            <div
              className="offer-details__arrow d-xl-none"
              onClick={() =>
                window.scrollTo({
                  top:
                    document.querySelector(".offer-details__item-summary")
                      .offsetTop - 65,
                  behavior: "smooth",
                })
              }
              style={{
                backgroundImage: `url(${
                  require(`../assets/images/icons/arrow-black.svg`).default
                })`,
              }}
            ></div>
          </div>
          <div className="offer-details__item bg-green">
            <div className="offer-details__item-summary">
              <div className="offer-details__item-summary-title">
                {flat.number}
                <b className="d-none d-md-block">{setStatus(flat.state)}</b>

                {flat.state !== "sold" && (
                  <>
                    <b className="d-none d-md-block">
                      {flat.priceOffer.brutto
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                      &nbsp;zł
                    </b>

                    {(flat.number === "U5" || flat.number === "U8") && (
                      <div className="d-none d-md-block promo">
                        Cena promocyjna: <b>11 500 zł/m2 netto</b>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="offer-details__item-summary-info">
                <p>
                  Powierzchnia:
                  <b>
                    {flat.area} m<sup>2</sup>
                  </b>
                </p>
                {flat.roomsNumber !== 0 && (
                  <p>
                    Pokoje: <b>{flat.roomsNumber}</b>
                  </p>
                )}
                <p>
                  Piętro:
                  {flat.floor === 0 ? (
                    <b>parter</b>
                  ) : (
                    <b>{flat.floor} piętro</b>
                  )}
                </p>
                {/* <p>
                           Kierunki świata: <b></b>
                        </p> */}
              </div>
              <div className="offer-details__item-summary-image"></div>
              <div className="offer-details__item-summary-files">
                <div className="offer-details__item-summary-files-item">
                  <a
                    href={
                      flat.pdf &&
                      `https://proxin.thtg.pl/api/v1/public/download/${flat.pdf.file.id}`
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="offer-details__item-summary-files-item-image"></div>
                    <p className="offer-details__item-summary-files-item-text">
                      Pobierz kartę mieszkania
                    </p>
                  </a>
                </div>
                <div className="offer-details__item-summary-files-item">
                  <div
                    className="offer-details__item-summary-files-item-btn"
                    onClick={(e) => toggleForm(e)}
                  >
                    <div className="offer-details__item-summary-files-item-image"></div>
                    <p className="offer-details__item-summary-files-item-text">
                      Zapytaj o mieszkanie
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div className="offer-loading ps-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p> Wczytywanie...</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <footer
        className="footer-offer"
        id="footer"
        style={{
          backgroundImage: `url(${require(`../assets/images/texture.jpg`)})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 d-xl-flex justify-content-xl-between align-items-center">
              <p className="footer-offer__text">
                Podane na karcie wymiary oraz powierzchnie pomieszczeń mają
                charakter projektowy i mogą nieznacznie różnić się od wymiarów i
                powierzchni w wybudowanym budynku. Umeblowanie oraz
                zagospodarowanie terenu jest tylko wizualizacją i nie stanowi
                oferty handlowej – ma jedynie charakter poglądowy, przybliżający
                wielkość mieszkania
              </p>

              <div className="footer-offer__fb d-none d-xl-block">
                <a href="https://www.facebook.com/Slowackiego7">
                  <p>Obserwuj nas na&nbsp;Facebooku</p>
                  <div className="footer-offer__fb-img" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {props.data && (
        <FormAsk data={flat} isActive={form} toggle={toggleForm} />
      )}
    </>
  );
};

export default OfferItem;
