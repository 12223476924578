import React from "react";

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.canv = React.createRef();
  }

  state = {};

  canvasDraw = (e) => {
    const ctx = this.canv.current.getContext("2d");
    const popup = document.querySelectorAll(".floors__item");
    const wrapper = document.querySelector(".floors__building");

    ctx.clearRect(
      0,
      0,
      wrapper.getClientRects()[0].width,
      wrapper.getClientRects()[0].height
    );

    const floor5 = new Path2D();

    if (window.matchMedia("(max-width: 767px)").matches) {
      floor5.moveTo(42, 42);
      floor5.lineTo(44, 28);
      floor5.lineTo(309, 5);
      floor5.lineTo(311, 22);
    } else if (window.matchMedia("(max-width: 1199px)").matches) {
      floor5.moveTo(118, 89);
      floor5.lineTo(122, 61);
      floor5.lineTo(697, 12);
      floor5.lineTo(702, 50);
    } else if (window.matchMedia("(max-width: 1779px)").matches) {
      floor5.moveTo(152, 115);
      floor5.lineTo(157, 78);
      floor5.lineTo(895, 15);
      floor5.lineTo(898, 62);
    } else {
      floor5.moveTo(235, 178);
      floor5.lineTo(242, 120);
      floor5.lineTo(1395, 21);
      floor5.lineTo(1400, 95);
    }

    const floor4 = new Path2D();

    if (window.matchMedia("(max-width: 767px)").matches) {
      floor4.moveTo(40, 52);
      floor4.lineTo(42, 42);
      floor4.lineTo(311, 22);
      floor4.lineTo(311, 35);
    } else if (window.matchMedia("(max-width: 1199px)").matches) {
      floor4.moveTo(116, 112);
      floor4.lineTo(118, 89);
      floor4.lineTo(700, 50);
      floor4.lineTo(702, 80);
    } else if (window.matchMedia("(max-width: 1779px)").matches) {
      floor4.moveTo(148, 145);
      floor4.lineTo(152, 115);
      floor4.lineTo(897, 62);
      floor4.lineTo(899, 100);
    } else {
      floor4.moveTo(227, 228);
      floor4.lineTo(235, 178);
      floor4.lineTo(1399, 95);
      floor4.lineTo(1403, 155);
    }

    const floor3 = new Path2D();

    if (window.matchMedia("(max-width: 767px)").matches) {
      floor3.moveTo(39, 64);
      floor3.lineTo(41, 52);
      floor3.lineTo(312, 35);
      floor3.lineTo(312, 48);
    } else if (window.matchMedia("(max-width: 1199px)").matches) {
      floor3.moveTo(112, 132);
      floor3.lineTo(115, 112);
      floor3.lineTo(702, 80);
      floor3.lineTo(704, 110);
    } else if (window.matchMedia("(max-width: 1779px)").matches) {
      floor3.moveTo(144, 175);
      floor3.lineTo(147, 145);
      floor3.lineTo(899, 100);
      floor3.lineTo(902, 140);
    } else {
      floor3.moveTo(223, 267);
      floor3.lineTo(229, 225);
      floor3.lineTo(1403, 155);
      floor3.lineTo(1409, 215);
    }

    const floor2 = new Path2D();

    if (window.matchMedia("(max-width: 767px)").matches) {
      floor2.moveTo(40, 76);
      floor2.lineTo(40, 64);
      floor2.lineTo(312, 48);
      floor2.lineTo(312, 63);
    } else if (window.matchMedia("(max-width: 1199px)").matches) {
      floor2.moveTo(112, 162);
      floor2.lineTo(112, 132);
      floor2.lineTo(704, 110);
      floor2.lineTo(704, 140);
    } else if (window.matchMedia("(max-width: 1779px)").matches) {
      floor2.moveTo(144, 205);
      floor2.lineTo(147, 175);
      floor2.lineTo(902, 140);
      floor2.lineTo(902, 180);
    } else {
      floor2.moveTo(221, 317);
      floor2.lineTo(223, 267);
      floor2.lineTo(1407, 215);
      floor2.lineTo(1407, 280);
    }

    const floor1 = new Path2D();

    if (window.matchMedia("(max-width: 767px)").matches) {
      floor1.moveTo(40, 86);
      floor1.lineTo(40, 76);
      floor1.lineTo(312, 63);
      floor1.lineTo(312, 80);
    } else if (window.matchMedia("(max-width: 1199px)").matches) {
      floor1.moveTo(112, 185);
      floor1.lineTo(112, 162);
      floor1.lineTo(704, 140);
      floor1.lineTo(704, 170);
    } else if (window.matchMedia("(max-width: 1779px)").matches) {
      floor1.moveTo(144, 235);
      floor1.lineTo(144, 205);
      floor1.lineTo(902, 180);
      floor1.lineTo(902, 220);
    } else {
      floor1.moveTo(221, 367);
      floor1.lineTo(223, 315);
      floor1.lineTo(1407, 280);
      floor1.lineTo(1407, 340);
    }

    const floor0 = new Path2D();

    if (window.matchMedia("(max-width: 767px)").matches) {
      floor0.moveTo(40, 96);
      floor0.lineTo(40, 86);
      floor0.lineTo(312, 80);
      floor0.lineTo(312, 98);
    } else if (window.matchMedia("(max-width: 1199px)").matches) {
      floor0.moveTo(112, 210);
      floor0.lineTo(112, 185);
      floor0.lineTo(704, 170);
      floor0.lineTo(704, 210);
    } else if (window.matchMedia("(max-width: 1779px)").matches) {
      floor0.moveTo(144, 268);
      floor0.lineTo(144, 235);
      floor0.lineTo(902, 220);
      floor0.lineTo(902, 267);
    } else {
      floor0.moveTo(229, 417);
      floor0.lineTo(223, 367);
      floor0.lineTo(1407, 340);
      floor0.lineTo(1407, 420);
    }

    //  ctx.beginPath();
    //  ctx.moveTo(229, 417);
    //  ctx.lineTo(223, 367);
    //  ctx.lineTo(1407, 340);
    //  ctx.lineTo(1407, 420);
    //  ctx.closePath();
    //  ctx.fillStyle = "rgba(44, 71, 31, 0.5)";
    //  ctx.fill();

    if (window.matchMedia("(min-width:1200px)").matches) {
      this.canv.current.addEventListener("mousemove", handleClickCanv);
    } else {
      this.canv.current.addEventListener("touchstart", handleClickCanv);

      document.body.addEventListener("touchstart", function (e) {
        if (e.target.closest(".building-canv") === null) {
          ctx.clearRect(
            0,
            0,
            document.querySelector(".floors__building").getClientRects()[0]
              .width,
            document.querySelector(".floors__building").getClientRects()[0]
              .height
          );

          popup.forEach((item) => {
            item.classList.remove("active");
          });
        }
      });
    }

    const canvCls = this;

    function handleClickCanv(e) {
      let currentShape;

      let mouseX;
      let mouseY;

      if (e.type === "touchstart") {
        mouseX =
          e.touches[0].clientX -
          document.querySelector(".building-canv").getBoundingClientRect().x;
        mouseY =
          e.touches[0].clientY -
          document.querySelector(".building-canv").getBoundingClientRect().y;
      } else {
        mouseX = e.offsetX;
        mouseY = e.offsetY;
      }

      if (ctx.isPointInPath(floor5, mouseX, mouseY)) {
        currentShape = 5;
      } else if (ctx.isPointInPath(floor4, mouseX, mouseY)) {
        currentShape = 4;
      } else if (ctx.isPointInPath(floor3, mouseX, mouseY)) {
        currentShape = 3;
      } else if (ctx.isPointInPath(floor2, mouseX, mouseY)) {
        currentShape = 2;
      } else if (ctx.isPointInPath(floor1, mouseX, mouseY)) {
        currentShape = 1;
      } else if (ctx.isPointInPath(floor0, mouseX, mouseY)) {
        currentShape = 0;
      }

      if (
        ctx.isPointInPath(floor5, mouseX, mouseY) ||
        ctx.isPointInPath(floor4, mouseX, mouseY) ||
        ctx.isPointInPath(floor3, mouseX, mouseY) ||
        ctx.isPointInPath(floor2, mouseX, mouseY) ||
        ctx.isPointInPath(floor1, mouseX, mouseY) ||
        ctx.isPointInPath(floor0, mouseX, mouseY)
      ) {
        ctx.fillStyle = "rgba(144, 171, 131, 0.5)";
        document.body.style.cursor = "pointer";

        popup[currentShape].classList.add("active");

        canvCls.props.flrSet(popup[currentShape].dataset.floor);

        // const href1 = document
        //    .querySelector(".floor-home-btn")
        //    .getAttribute("data-href");

        // // const href2 = href1.split("floor=")[1];

        // const href2 = href1.replace(
        //    href1,
        //    popup[currentShape].dataset.floor
        // );

        // document
        //    .querySelector(".floor-home-btn")
        //    .setAttribute("data-href", href2);

        popup.forEach((item, index) => {
          if (index !== currentShape) {
            item.classList.remove("active");
          }
        });

        if (mouseX - popup[currentShape].offsetWidth - 5 < 5) {
          popup[currentShape].style.left = mouseX / 2 + 10 + "px";
        } else {
          popup[currentShape].style.left =
            mouseX - popup[currentShape].offsetWidth - 5 + 10 + "px";
        }

        popup[currentShape].style.top =
          mouseY - popup[currentShape].offsetHeight - 5 + 10 + "px";
      } else {
        document.body.style.cursor = "default";
        ctx.fillStyle = "rgba(0, 0, 255, 0)";

        popup.forEach((item) => {
          item.classList.remove("active");
        });
      }

      ctx.clearRect(
        0,
        0,
        wrapper.getClientRects()[0].width,
        wrapper.getClientRects()[0].height
      );

      if (currentShape === 0) {
        ctx.fill(floor0);
      }
      if (currentShape === 1) {
        ctx.fill(floor1);
      }
      if (currentShape === 2) {
        ctx.fill(floor2);
      }
      if (currentShape === 3) {
        ctx.fill(floor3);
      }
      if (currentShape === 4) {
        ctx.fill(floor4);
      }
      if (currentShape === 5) {
        ctx.fill(floor5);
      }
    }
  };

  componentDidMount() {
    this.drawClass();

    // window.onresize = this.drawClass;
  }

  drawClass() {
    this.canv.current.width = document
      .querySelector(".floors__building")
      .getClientRects()[0].width;
    this.canv.current.height = document
      .querySelector(".floors__building")
      .getClientRects()[0].height;

    this.canvasDraw();
  }

  render() {
    return (
      <>
        <canvas
          className="building-canv"
          ref={this.canv}
          onClick={() => document.querySelector(".floor-home-btn").click()}
        ></canvas>
      </>
    );
  }
}

export default Canvas;
