import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Popup from "./components/Popup";
import Header from "./components/Header";
import Location from "./components/Location";
import Video from "./components/Video";
import Tiles from "./components/Tiles";
import Investment from "./components/Investment";
import Rectangle from "./components/Rectangle";
import Standard from "./components/Standard";
import Green from "./components/Green";
import Investor from "./components/Investor";
import Flat from "./components/Flat";
import Footer from "./components/Footer";
import Form from "./components/Form";
import ZommedImage from "./components/ZommedImage";
import Map from "./components/Map";
import No from "./components/No";
import Sketch from "./components/Sketch";
import ImageSlider from "./components/ImageSlider";
import Prestige from "./components/Prestige";
import Plus from "./components/Plus";
import Floors from "./components/Floors";
import GreenMap from "./components/GreenMap";
import ImagesRow from "./components/ImagesRow";
import Policy from "./pages/Policy";
import Offer from "./pages/Offer";
import OfferItem from "./pages/OfferItem";
// import Error from "./pages/Error";

class App extends React.Component {
  state = {
    flats: null,
  };

  allSections = [];

  appLoadFun = () => {
    document.body.style = "";
    document.body.removeAttribute("class");
    // window.scrollTo(0, 0);

    if (
      window.location.pathname.search("/oferta/") !== -1 ||
      window.location.pathname === "/polityka-prywatnosci"
    )
      return;

    this.setClickableImages();

    window.onresize = this.onResizeWindow;

    document.querySelectorAll(".section--with-anim").forEach((section) => {
      this.allSections.push([section.offsetTop, section.offsetHeight, section]);
    });

    if (window.location.pathname === "/oferta") {
      if (window.scrollY > document.querySelector(".header__top").offsetTop) {
        document.querySelector(".header__side-menu").classList.remove("hidden");
      } else {
        document.querySelector(".header__side-menu").classList.add("hidden");
      }
    } else {
      if (
        window.scrollY >
        document.querySelector("section:nth-of-type(1)").offsetTop - 50
      ) {
        document.querySelector(".header__side-menu").classList.remove("hidden");
      } else {
        document.querySelector(".header__side-menu").classList.add("hidden");
      }
    }

    if (
      window.matchMedia("(min-width: 1200px)").matches &&
      window.matchMedia("(max-width: 1700px)").matches
    ) {
      if (
        window.scrollY >
        document.getElementById("footer").offsetTop - window.innerHeight
      ) {
        document
          .querySelector(".header__side-menu")
          .classList.add("move-bottom");
      } else {
        document
          .querySelector(".header__side-menu")
          .classList.remove("move-bottom");
      }
    }

    window.addEventListener("scroll", this.setScrollAnim);
  };

  onResizeWindow = () => {
    this.allSections = [];

    document.querySelectorAll(".section--with-anim").forEach((section) => {
      this.allSections.push([section.offsetTop, section.offsetHeight, section]);
    });
  };

  resetFun = () => {
    window.removeEventListener("scroll", this.setScrollAnim);
    // document.body.style = "";
  };

  setScrollAnim = () => {
    if (
      window.location.pathname.search("/oferta/") !== -1 ||
      window.location.pathname === "/polityka-prywatnosci"
    )
      return;

    this.allSections.forEach((item) => {
      if (window.matchMedia("(min-width: 1200px)").matches) {
        if (
          window.scrollY > item[0] - window.innerHeight / 2 &&
          window.scrollY < item[0] + item[1] / 2
        ) {
          item[2].classList.add("anim");
          item[2].classList.add("anim-text");
        } else {
          item[2].classList.remove("anim");
        }
        if (window.location.pathname === "/") {
          if (item[2].classList.contains("standard") === false) {
            document.querySelector(".imagesRow").classList.add("anim-text");
          }
        }
      } else {
        if (
          window.scrollY > item[0] - window.innerHeight / 2 &&
          window.scrollY < item[0] - 65
        ) {
          item[2].classList.add("anim");
          item[2].classList.add("anim-text");
        } else {
          item[2].classList.remove("anim");
        }
        if (window.location.pathname === "/") {
          if (item[2].classList.contains("standard") === false) {
            document.querySelector(".imagesRow").classList.add("anim-text");
          }
        }
      }

      if (
        window.matchMedia("(min-width: 1200px)").matches &&
        window.matchMedia("(max-width: 1700px)").matches
      ) {
        if (
          window.scrollY >
          document.getElementById("footer").offsetTop - window.innerHeight
        ) {
          document
            .querySelector(".header__side-menu")
            .classList.add("move-bottom");
        } else {
          document
            .querySelector(".header__side-menu")
            .classList.remove("move-bottom");
        }
      }
    });

    if (window.location.pathname === "/oferta") {
      if (window.scrollY > document.querySelector(".header__top").offsetTop) {
        document.querySelector(".header__side-menu").classList.remove("hidden");
      } else {
        document.querySelector(".header__side-menu").classList.add("hidden");
        document.body.classList.remove("move");
      }
    } else {
      if (
        window.scrollY >
        document.querySelector("section:nth-of-type(1)").offsetTop - 50
      ) {
        document.querySelector(".header__side-menu").classList.remove("hidden");
      } else {
        document.querySelector(".header__side-menu").classList.add("hidden");
        document.body.classList.remove("move");
      }
    }

    if (window.location.pathname === "/") {
      if (
        window.scrollY <
        document.querySelector("header").offsetTop +
          document.querySelector("header").offsetHeight
      ) {
        let zoomRatio = 2000;
        if (window.matchMedia("(min-width: 1170px)").matches) {
          zoomRatio = 5000;
        }
        const zoom = document.documentElement.scrollTop / zoomRatio + 1;

        document.querySelector(
          ".header__banner"
        ).style.transform = `scale(${zoom})`;
        if (
          window.scrollY >
          (document.querySelector("header").offsetTop +
            document.querySelector("header").offsetHeight) /
            3
        ) {
          document.querySelector(".header__banner-img").classList.add("anim");
        } else {
          document
            .querySelector(".header__banner-img")
            .classList.remove("anim");
        }
      }
    }

    if (window.scrollY > 95) {
      document.querySelector(".header__top").classList.add("moved");
    } else {
      document.querySelector(".header__top").classList.remove("moved");
    }
  };

  componentDidMount() {
    this.getData();

    this.resetFun();

    this.appLoadFun();
  }

  setClickableImages() {
    const clickableImages = document.querySelectorAll(".clickable-image");

    clickableImages !== null &&
      clickableImages.forEach((image) => {
        image.onclick = (e) => {
          document.querySelector(
            ".zoomed-image .zoomed-image__box"
          ).style.backgroundImage = e.target.style.backgroundImage;

          document.body.style.overflowY = "hidden";

          document.querySelector(".zoomed-image").removeAttribute("hidden");
        };
      });
  }

  getData = async () => {
    fetch(
      `https://proxin.thtg.pl/api/v1/private/product?filter=[{"type":"lte","field":"type","value":2},{"type":"eq","field":"investment","value":6}]`,
      {
        method: "GET", // or 'PUT'
        headers: {
          Authorization: "Thtg c75d295ba408843eda08bd722611a276c76b3dbc",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw Error(response.status);
      })
      .then((result) => {
        this.setState({
          flats: result.data,
        });
      })
      .catch((error) => console.log(error + " error"));
  };

  render() {
    return (
      <>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <ZommedImage />
                  <Location />
                  <Map />
                  <No />
                  <Video />
                  <Tiles />
                  <Investment />
                  <Sketch />
                  <Rectangle />
                  <Standard />
                  <ImageSlider />
                  <ImagesRow />
                  <Prestige />
                  <Plus />
                  <Green />
                  <GreenMap />
                  <Investor />
                  <Flat />
                  <Floors data={this.state.flats} />
                  <Rectangle showText={true} />
                  <Form />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/polityka-prywatnosci"
              element={
                <Policy reset={this.resetFun} appLoadFun={this.appLoadFun} />
              }
            ></Route>
            <Route
              path="/oferta"
              element={
                <>
                  {" "}
                  <Offer
                    reset={this.resetFun}
                    appLoadFun={this.appLoadFun}
                    data={this.state.flats}
                  />
                  <Rectangle showText={true} />
                  <Form />
                  <Footer />
                </>
              }
            ></Route>
            <Route
              path="/oferta/:offerId"
              element={
                <OfferItem
                  reset={this.resetFun}
                  appLoadFun={this.appLoadFun}
                  data={this.state.flats}
                />
              }
            ></Route>
            {/* <Route>
                     <Error />
                  </Route> */}
          </Routes>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
