// import React from "react";

export const Plus = () => {
  return (
    <>
      <section className="plus section--with-anim" id="plus">
        <div className="container">
          <div
            className="plus__image image-bg"
            style={{
              backgroundImage: `url(${require("../assets/images/image3.jpg")})`,
            }}
          >
            <div className="plus__image-item">
              <span>+</span>
              <div className="plus__image-item-text">
                zielona strefa aktywności dla&nbsp;mieszkańców
              </div>
            </div>
            <div className="plus__image-item">
              <span>+</span>
              <div className="plus__image-item-text">przestronne balkony</div>
            </div>
            <div className="plus__image-item">
              <span>+</span>
              <div className="plus__image-item-text">parking podziemny</div>
            </div>
            <div className="plus__image-item">
              <span>+</span>
              <div className="plus__image-item-text">
                ogródki z tarasami na dziedzińcu
              </div>
            </div>
            <div className="plus__image-item">
              <span>+</span>
              <div className="plus__image-item-text">
                sklepy i lokale usługowe na parterze
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Plus;
