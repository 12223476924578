export const Video = () => {
   return (
      <>
         <section className="video" id="video">
            <div className="container">
               <div className="row">
                  <div className="col-12">
                     <div className="video__box">
                        <iframe
                           src="https://www.youtube.com/embed/FyUv7FCJ838?modestbranding=1&autohide=1&showinfo=0&rel=0"
                           title="YouTube video player"
                           frameBorder="0"
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                           allowFullScreen
                        ></iframe>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Video;
