export const Prestige = () => {
   return (
      <>
         <section className="prestige section--with-anim" id="prestige">
            <div className="container">
               <div className="row">
                  <div className="col-12 col-md-8 m-auto col-xl-4 col--small">
                     <h2>
                        PRESTIŻ <br className="d-none d-xxl-block" /> W
                        <br className="d-xxl-none" /> STANDARDZIE
                     </h2>
                  </div>
                  <div className="col-12 col-md-8 m-auto col-xl-8 col--small">
                     <div className="app__text-box">
                        <div className="line"></div>
                        <p className="app__text">
                           By zapewnić przyszłym mieszkańcom jak największą
                           ilość udogodnień, zadbamy o przemyślane
                           zagospodarowanie terenu wokół budynków. Pojawi się
                           zielona strefa odpoczynku czy stojaki na rowery wraz
                           ze stacją napraw.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Prestige;
