import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

export const FloorProjection = (props) => {
  useEffect(() => {
    if (window.matchMedia("(min-width: 1200px)").matches) {
      setPopups();
    }

    if (
      window.location.href.split("floor=")[1] === "0" ||
      window.location.href.split("floor=")[1] === "1" ||
      window.location.href.split("floor=")[1] === "2" ||
      window.location.href.split("floor=")[1] === "3" ||
      window.location.href.split("floor=")[1] === "4" ||
      window.location.href.split("floor=")[1] === "5"
    )
      document
        .querySelector(
          `.floor-projection__floor ul li:nth-of-type(${
            Number(window.location.href.split("floor=")[1]) + 1
          })`
        )
        .click();
  }, []);

  const [idUrl, setIdUrl] = useState("");

  const chooseFloor = (e) => {
    e.target
      .closest(".floor-projection__floor")
      .querySelector("span").textContent = e.target.textContent;
    e.target.closest(".floor-projection__floor").blur();

    e.target
      .closest(".floor-projection")
      .setAttribute("data-floor", e.target.dataset.value);

    e.target
      .closest("ul")
      .querySelectorAll("li")
      .forEach((item) => {
        item.classList.remove("active");
      });
    e.target.classList.add("active");
  };

  const setPopups = () => {
    document
      .querySelectorAll(".floor-projection__item-image-flat")
      .forEach((item) => {
        item.onmouseenter = (e) => showPopup(e, item);
        item.onmouseleave = (e) => hidePopup(e, item);
      });
  };

  const showPopup = (mouse, target) => {
    document
      .querySelector(".floor-projection__item-image-flat-info")
      .classList.add("active");
    target.onmousemove = (e) => popupPos(e, target);

    const floor = document.querySelector(".floor-projection").dataset.floor;

    let flat;

    if (floor === "0") {
      if (target.dataset.number === "10") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "U1";
        });
      }
      if (target.dataset.number === "9") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "U2";
        });
      }
      if (target.dataset.number === "8") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "U3";
        });
      }
      if (target.dataset.number === "7") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "U4";
        });
      }
      if (target.dataset.number === "6") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "U5";
        });
      }
      if (target.dataset.number === "5") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "U6";
        });
      }
      if (target.dataset.number === "4") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "U7";
        });
      }
      if (target.dataset.number === "3") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "U8";
        });
      }
      if (target.dataset.number === "2") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "U9";
        });
      }
      if (target.dataset.number === "1") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "U10";
        });
      }
    }

    if (floor === "1") {
      if (target.dataset.number === "1") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7B/2";
        });
      }
      if (target.dataset.number === "2") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7B/1";
        });
      }
      if (target.dataset.number === "3") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/3";
        });
      }
      if (target.dataset.number === "4") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/2";
        });
      }
      if (target.dataset.number === "5") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/1";
        });
      }
      if (target.dataset.number === "6") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/7";
        });
      }
      if (target.dataset.number === "7") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/6";
        });
      }
      if (target.dataset.number === "8") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/8";
        });
      }
      if (target.dataset.number === "9") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/5";
        });
      }
      if (target.dataset.number === "10") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/1";
        });
      }
      if (target.dataset.number === "11") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/4";
        });
      }
      if (target.dataset.number === "12") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/2";
        });
      }
      if (target.dataset.number === "13") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/3";
        });
      }
    }

    if (floor === "2") {
      if (target.dataset.number === "1") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7B/4";
        });
      }
      if (target.dataset.number === "2") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7B/3";
        });
      }
      if (target.dataset.number === "3") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/6";
        });
      }
      if (target.dataset.number === "4") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/5";
        });
      }
      if (target.dataset.number === "5") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/4";
        });
      }
      if (target.dataset.number === "6") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/15";
        });
      }
      if (target.dataset.number === "7") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/14";
        });
      }
      if (target.dataset.number === "8") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/16";
        });
      }
      if (target.dataset.number === "9") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/13";
        });
      }
      if (target.dataset.number === "10") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/9";
        });
      }
      if (target.dataset.number === "11") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/12";
        });
      }
      if (target.dataset.number === "12") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/10";
        });
      }
      if (target.dataset.number === "13") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/11";
        });
      }
    }

    if (floor === "3") {
      if (target.dataset.number === "1") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7B/6";
        });
      }
      if (target.dataset.number === "2") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7B/5";
        });
      }
      if (target.dataset.number === "3") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/9";
        });
      }
      if (target.dataset.number === "4") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/8";
        });
      }
      if (target.dataset.number === "5") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/7";
        });
      }
      if (target.dataset.number === "6") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/23";
        });
      }
      if (target.dataset.number === "7") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/22";
        });
      }
      if (target.dataset.number === "8") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/24";
        });
      }
      if (target.dataset.number === "9") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/21";
        });
      }
      if (target.dataset.number === "10") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/17";
        });
      }
      if (target.dataset.number === "11") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/20";
        });
      }
      if (target.dataset.number === "12") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/18";
        });
      }
      if (target.dataset.number === "13") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/19";
        });
      }
    }

    if (floor === "4") {
      if (target.dataset.number === "1") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7B/8";
        });
      }
      if (target.dataset.number === "2") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7B/7";
        });
      }

      if (target.dataset.number === "16") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/11";
        });
      }

      if (target.dataset.number === "5") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/10";
        });
      }
      if (target.dataset.number === "6") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/31";
        });
      }
      if (target.dataset.number === "7") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/30";
        });
      }
      if (target.dataset.number === "8") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/32";
        });
      }
      if (target.dataset.number === "9") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/29";
        });
      }
      if (target.dataset.number === "10") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/25";
        });
      }
      if (target.dataset.number === "11") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/28";
        });
      }
      if (target.dataset.number === "12") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/26";
        });
      }
      if (target.dataset.number === "13") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/27";
        });
      }
    }

    if (floor === "5") {
      if (target.dataset.number === "1") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7B/10";
        });
      }
      if (target.dataset.number === "2") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7B/9";
        });
      }

      if (target.dataset.number === "16") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/13";
        });
      }

      if (target.dataset.number === "5") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7A/12";
        });
      }

      if (target.dataset.number === "8") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/32";
        });
      }
      if (target.dataset.number === "9") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/37";
        });
      }

      if (target.dataset.number === "11") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/36";
        });
      }
      if (target.dataset.number === "12") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/34";
        });
      }
      if (target.dataset.number === "13") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/35";
        });
      }
      if (target.dataset.number === "14") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/38";
        });
      }
      if (target.dataset.number === "15") {
        flat = props.data.filter((item) => {
          return item.number.replace(/\s/g, "") === "7/33";
        });
      }
    }

    target.onclick = () => {
      setIdUrl(flat[0].id);

      if (flat[0].state !== "sold") {
        document.querySelector(".redirect-to").click();
      }
    };

    // target.onclick = () =>
    //    document.querySelector(".redirect-to").click()(
    let status;

    if (flat[0].state === "available") {
      status = "wolne";
    } else if (flat[0].state === "reservation") {
      status = "zarezerwowane";
    } else if (flat[0].state === "preReservation") {
      status = "zarezerwowane";
    } else if (flat[0].state === "sold") {
      status = "sprzedane";
    }

    target.setAttribute("data-status", flat[0].state);

    if (
      (floor === "0" && target.dataset.number === "6") ||
      (floor === "0" && target.dataset.number === "3")
    ) {
      if (status !== "sprzedane")
        document
          .querySelector(".floor-projection__item-image-flat-info p:last-child")
          .classList.add("show");
    }

    document.querySelector(
      ".floor-projection__item-image-flat-info p:nth-of-type(1)>b>span:nth-child(1)"
    ).textContent = flat[0].number;

    document.querySelector(
      ".floor-projection__item-image-flat-info p:nth-of-type(1)>b>span:nth-child(2)"
    ).textContent = status;

    document.querySelector(
      ".floor-projection__item-image-flat-info p:nth-of-type(2)>b>span"
    ).textContent = flat[0].area;

    document.querySelector(
      ".floor-projection__item-image-flat-info p:nth-of-type(3)>b>span"
    ).textContent = flat[0].roomsNumber;

    if (status !== "sprzedane") {
      document.querySelector(
        ".floor-projection__item-image-flat-info p:nth-of-type(4)>b>span"
      ).textContent = flat[0].priceOffer.brutto
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else {
      document.querySelector(
        ".floor-projection__item-image-flat-info p:nth-of-type(4)>b>span"
      ).textContent = "-";
    }
  };

  const popupPos = (mouse) => {
    document.querySelector(
      ".floor-projection__item-image-flat-info"
    ).style.left = mouse.clientX + "px";
    document.querySelector(
      ".floor-projection__item-image-flat-info"
    ).style.top = mouse.clientY + "px";
  };

  const hidePopup = (mouse, target) => {
    document
      .querySelector(".floor-projection__item-image-flat-info")
      .classList.remove("active");
    target.onmousemove = null;
    document
      .querySelector(".floor-projection__item-image-flat-info p:last-child")
      .classList.remove("show");
  };

  return (
    <>
      <div className="floor-projection d-none d-xl-block" data-floor="0">
        <div className="row">
          <div className="col-12">
            <div className="floor-projection__item">
              <div className="floor-projection__item-title">
                <b>M 7B</b>
                <b>M 7A</b>
                <b>M 7</b>
              </div>
              <div className="floor-projection__item-image">
                <div
                  className="floor-projection__item-image-flat"
                  data-number="1"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="2"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="3"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="4"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="5"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="6"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="7"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="8"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="9"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="10"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="11"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="12"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="13"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="14"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="15"
                ></div>
                <div
                  className="floor-projection__item-image-flat"
                  data-number="16"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-7 col-xxl-8 d-flex align-items-end justify-content-end">
            <div className="info">Ceny lokali usługowych są cenami netto</div>
          </div>
          <div className="col-5 col-xxl-4 d-flex justify-content-end">
            <div className="floor-projection__floor-choose">
              <label
                htmlFor="floor-projection__floor"
                className="floor-projection__floor-title"
              >
                Wybierz piętro
              </label>
              <button
                id="floor-projection__floor"
                name="floor-projection__floor"
                className="floor-projection__floor"
              >
                <span>PARTER</span>
                <ul>
                  <li data-value="0" onClick={chooseFloor} className="active">
                    PARTER
                  </li>
                  <li data-value="1" onClick={chooseFloor}>
                    PIĘTRO I
                  </li>
                  <li data-value="2" onClick={chooseFloor}>
                    PIĘTRO II
                  </li>
                  <li data-value="3" onClick={chooseFloor}>
                    PIĘTRO III
                  </li>
                  <li data-value="4" onClick={chooseFloor}>
                    PIĘTRO IV
                  </li>
                  <li data-value="5" onClick={chooseFloor}>
                    PIĘTRO V
                  </li>
                </ul>
              </button>
            </div>
          </div>
        </div>
        <NavLink to={`/oferta/${idUrl}`} className="redirect-to"></NavLink>
      </div>
    </>
  );
};

export default FloorProjection;
