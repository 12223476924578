import { NavLink } from "react-router-dom";

export const OfferResult = (props) => {
  const flat = {
    status: props.data.state,
    number: props.data.number,
    id: props.data.id,
    area: props.data.area,
    rooms: props.data.roomsNumber,
    floor: props.data.floor,
    price: {
      full: props.data.priceOffer.brutto,
      area: props.data.priceOffer.areaBrutto,
    },
  };

  const setStatus = () => {
    switch (props.data.state) {
      case "available":
        flat.status = "wolne";
        break;

      case "reservation":
        flat.status = "zarezerwowane";
        break;

      case "preReservation":
        flat.status = "zarezerwowane";
        break;

      case "sold":
        flat.status = "sprzedane";
        break;

      default:
        break;
    }
  };

  setStatus();

  return (
    <>
      <div className="col-12">
        <div className="offer-results__item">
          <div className="offer-results__item-col-left">
            <h3 className="offer-results__item-title">
              {flat.number}
              <b>{flat.status}</b>
            </h3>

            <div
              className="offer-results__item-image"
              style={{
                backgroundImage: `url(${require(`../assets/images/flats/${flat.id}.png`)})`,
              }}
            ></div>
          </div>

          <div className="offer-results__item-data">
            <div className="offer-results__item-data-item">
              Powierzchnia:
              <b>
                {flat.area} m<sup>2</sup>
              </b>
            </div>
            {flat.rooms !== 0 && (
              <div className="offer-results__item-data-item">
                Pokoje:
                <b>{flat.rooms}</b>
              </div>
            )}
            <div className="offer-results__item-data-item">
              Piętro:
              {flat.floor === 0 ? <b>parter</b> : <b>{flat.floor} piętro</b>}
            </div>

            {/* <div className="offer-results__item-data-item">
                     Kierunki świata:
                     <b></b>
                  </div> */}
          </div>

          <div className="offer-results__item-data-summary">
            {flat.status !== "sprzedane" && (
              <>
                <div className="offer-results__item-data-full-price">
                  {flat.price.full
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                  &nbsp;zł
                </div>

                <div className="offer-results__item-data-area-price">
                  <p>Cena za m2:</p>
                  <span>
                    {flat.price.area
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
                    &nbsp;zł
                  </span>

                  <br />

                  {(props.data.number === "U5" ||
                    props.data.number === "U8") && (
                    <div className="promo">
                      <p>Cena promocyjna:</p>
                      <span>
                        11 500 zł/m<sup>2</sup> netto
                      </span>
                    </div>
                  )}
                </div>
              </>
            )}

            <div className="d-flex justify-content-center justify-content-xl-start">
              <NavLink to={`/oferta/${flat.id}`}>
                <button className="offer__submit">więcej</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferResult;
