import { useEffect } from "react";
import { NavLink } from "react-router-dom";

export const Policy = (props) => {
  useEffect(() => {
    props.reset();
    window.scrollTo(0, 0);
    return () => {
      props.appLoadFun();
    };
  }, [props]);

  return (
    <>
      <section className="policy" id="policy">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-xxl-10">
              <div className="policy__top">
                <NavLink to="/">
                  <div className="policy__logo">
                    <img
                      src={require("../assets/images/logo.png")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </NavLink>

                <h2 className="policy__title">Polityka prywatności</h2>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-xxl-10">
              <h3 className="policy__text-title">1. Postanowienia ogólne</h3>
              <div className="policy__text">
                Celem niniejszego dokumentu jest przedstawienie zasad, na jakich
                chroniona jest prywatność i dane osobowe osób odwiedzających
                naszą stronę internetową pod adresem https://slowackiego7.pl (
                <b>„Strona”</b>) i korzystających z formularza kontaktowego
                zamieszczonego na tej stronie, dalej określanych jako{" "}
                <b>„Użytkownicy”</b>. <br />
                Dane Osobowe Użytkowników są gromadzone i przetwarzane zgodnie z
                obowiązującymi przepisami prawa, w tym zgodnie z Rozporządzeniem
                Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia
                2016 r. w sprawie ochrony osób fizycznych w związku z
                przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
                takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                rozporządzenie o ochronie danych – dalej <b>„RODO”</b>). <br />
                Administratorem danych osobowych przekazywanych przez
                Użytkowników korzystających z formularza kontaktowego
                znajdującego się na stronie (pkt. 2.C niniejszej Polityki) oraz
                podmiotem administrującym stroną internetową jest spółka Proxin
                Słowackiego spółka z ograniczoną odpowiedzialnością z siedzibą w
                Warszawie (03-802) ul. Skaryszewska 7, wpisana do rejestru
                przedsiębiorców Krajowego Rejestru Sądowego pod numerem KRS
                0000833935, której akta rejestrowe prowadzi Sąd Rejonowy dla
                Miasta Stołecznego Warszawy w Warszawie, Wydział XIII
                Gospodarczy Krajowego Rejestru Sądowego, dalej określana jako{" "}
                <b>„Administrator”</b>.
              </div>
            </div>

            <div className="col-12 col-xxl-10">
              <h3 className="policy__text-title">
                2. Rodzaje przetwarzanych danych, oraz cele i sposoby
                przetwarzania.
              </h3>
              <div className="policy__text policy__text--first">
                <b className="d-block">A. Odwiedziny na Stronie</b>
                Podczas odwiedzin na naszej stronie internetowej na nasz serwer
                przesyłane są informacje, które są automatycznie rejestrowane.
                Informacje te obejmują adres IP, rodzaj i nazwa urządzenia, data
                i czas odwiedzin naszego serwisu, informacje o przeglądarce
                internetowej i systemie operacyjnym. Dane te nie są powiązane z
                konkretną osobą. Są one przez nas wykorzystywane w celu
                diagnozowania problemów związanych z funkcjonowaniem Serwisu i
                analizowania ewentualnych naruszeń bezpieczeństwa, zarządzania
                Serwisem jak również w celu prowadzenia statystyk, co stanowi
                prawnie uzasadniony interes administratora, stanowiący
                jednocześnie podstawę prawną przetwarzania danych w tym celu
                (art. 6 ust. 1 lit. f RODO).
              </div>

              <div className="policy__text">
                <b className="d-block">B. Stosowanie ciasteczek</b>
                Na naszej Stronie wykorzystujemy tzw. „ciasteczka” (ang.
                „cookies”). Są to małe pliki tekstowe, które przeglądarka
                internetowa umieszcza i przechowuje na komputerze Użytkownika.
                Ich celem jest zoptymalizowanie naszej obecności w sieci i
                naszej oferty. <br />
                W większości są to tzw. „cookies sesyjne”, co oznacza że po
                zakończeniu sesji danej przeglądarki lub wyłączeniu komputera
                zapisane informacje są usuwane z pamięci urządzenia. Mechanizm
                cookies sesyjnych nie pozwala na pobieranie jakichkolwiek danych
                osobowych ani żadnych informacji poufnych z komputerów
                Użytkowników. <br />
                Niektóre ciasteczka generują informacje, pozwalające na
                automatyczne rozpoznawanie osób odwiedzających nasza Stronę.
                Jest to możliwe, ponieważ ciasteczka zwierają adres IP
                Użytkowników. Informacja uzyskana w ten sposób pozwala na
                zoptymalizowanie naszej oferty i ułatwienie Użytkownikom dostępu
                do naszej Strony. Tzw. „cookies trwałe” są przechowywane w
                pamięci urządzenia Użytkownika i pozostają tam do momentu ich
                skasowania lub wygaśnięcia. Mechanizm cookies trwałych również
                nie pozwala na pobieranie jakichkolwiek danych osobowych ani
                żadnych informacji poufnych z komputera Użytkownika.
                <br />
                Przeglądarki umożliwiają ograniczenie lub wyłączenie dostępu
                plików cookies. W przypadku skorzystania z tej opcji korzystanie
                z naszej Strony będzie nadal możliwe, jednak nie wszystkie
                funkcjonalności Strony mogą być dostępne. <br />
                Szczegółowe instrukcje zablokowania odbierania plików cookies
                można znaleźć na stronach dedykowanych poszczególnym
                przeglądarkom: <br />
                a) Chrome:{" "}
                <a href="https://support.google.com/chrome/answer/95647?hl=pl">
                  https://support.google.com/chrome/answer/95647?hl=pl
                </a>{" "}
                <br />
                b) Firefox:{" "}
                <a href="https://support.mozilla.org/pl/kb/ciasteczka">
                  https://support.mozilla.org/pl/kb/ciasteczka
                </a>
                <br />
                c) Internet Explorer:{" "}
                <a href="https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies">
                  https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies
                </a>{" "}
                <br />
                d) Microsoft Edge:{" "}
                <a href="https://support.microsoft.com/pl-pl/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">
                  https://support.microsoft.com/pl-pl/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy
                </a>{" "}
                <br />
                e) Opera:{" "}
                <a href="https://help.opera.com/pl/latest/web-preferences/#cookies">
                  https://help.opera.com/pl/latest/web-preferences/#cookies
                </a>{" "}
                <br />
                f) Safari:{" "}
                <a href="https://support.apple.com/pl-pl/HT201265">
                  https://support.apple.com/pl-pl/HT201265
                </a>
              </div>

              <div className="policy__text">
                <b className="d-block">
                  C. Wtyczki i narzędzia podmiotów trzecich
                </b>
                W Serwisie znajdują się osadzone przyciski, aplikacje i
                odesłania do usług innych firm, m.in.: wtyczki portali
                społecznościowych (np. Facebook YouTube). Korzystanie z tych
                aplikacji może powodować automatyczne wysyłanie informacji do
                podmiotów prowadzących te portale. Dane pozyskiwane w ten sposób
                będą przetwarzane w celach marketingowych, w tym w celu
                promowania marki Administratora, co stanowi prawnie uzasadniony
                interes Administratora i jednocześnie podstawę prawna
                przetwarzania danych (art. 6 ust. 1 lit. f) RODO). Podmioty
                prowadzące portale społecznościowe stają się współadmini
                stratorami danych przesyłanych do takich portali, a zasady i
                zakres przetwarzania danych przez te portale regulują odrębne
                polityki i regulaminy, zamieszczone na ich stronach. Szczegółowa
                informacja o przetwarzaniu przez Administratora danych osób
                korzystających z naszego profilu na Facebooku i na YT zawarta
                jest w informacji znajdującej się na profilach Administratora na
                tych serwisach.
                <a href="/" className="d-block">
                  [J.Sz1]
                </a>
                Strona wykorzystuje ponadto następujące technologie:
                <br />
                a) Google Analytics <br />
                Google Analytics gromadzi własne pliki cookie, czyli dane
                związane z urządzeniem oraz przeglądarką, adresem IP i
                działaniami użytkownika w witrynie lub aplikacji, by mierzyć i
                raportować statystyki interakcji użytkowników z witrynami i
                aplikacjami, które korzystają z Google Analytics. Do Google
                Analytics nie są wysyłane informacje umożliwiające identyfikację
                użytkownika. Informacje na temat bezpieczeństwa danych
                przetwarzanych za pomocą Google Analytics znajdują się pod
                następującym adresem:{" "}
                <a href="https://support.google.com/analytics/answer/6004245?hl=pl&ref_topic=2919631">
                  https://support.google.com/analytics/answer/6004245?hl=pl&ref_topic=2919631
                </a>
                <br />
                b) Google Ads <br />
                Google Ads (wcześniej Google AdWords i Google AdWords Express)
                to internetowe rozwiązanie reklamowe wykorzystywane do
                promowania produktów i usług w wyszukiwarce Google, w serwisie
                YouTube i na innych stronach. W ramach Google Ads wykorzystujemy
                funkcję reklam spersonalizowanych i remarketingu, które
                zwiększają dopasowanie reklam do użytkowników, w oparciu o dane
                o zachowaniu lub zainteresowaniach użytkowników, na podstawie
                informacji o ich wcześniejszych działaniach w internecie.
                Szczegółowe informacje o przetwarzaniu danych reklamowych przez
                Google znajdują się tutaj:{" "}
                <a href="https://policies.google.com/technologies/ads?hl=pl">
                  https://policies.google.com/technologies/ads?hl=pl
                </a>
              </div>

              <div className="policy__text">
                <b className="d-block">
                  D. Korzystanie z formularza kontaktowego
                </b>
                W razie skorzystania przez Użytkownika z formularza
                kontaktowego, gromadzimy następujące dane osobowe podane przez
                Użytkownika:
                <ul className="d-block">
                  <li>imię i nazwisko Użytkownika,</li>
                  <li>adres poczty elektronicznej (e-mail) Użytkownika,</li>
                  <li>numer telefonu Uzytkownika,</li>
                  <li>powiązaną z powyższymi danymi treść zapytania.</li>
                </ul>
                Dane te są przetwarzane przez Administratora w celu nawiązania
                kontaktu z Użytkownikami i udzielenia odpowiedzi na skierowane
                zapytanie lub przesłania materiałów sprzedażowych i rzutów
                lokali. Podstawą przetwarzania danych w tym celu jest zgoda
                wyrażona przez Użytkownika (co przewiduje art. 6 ust. 1 lit. a
                RODO). Wyrażenie takiej zgody jest dobrowolne, jest ono jednak
                warunkiem skorzystania z formularza zapytania. Wyrażenie zgody
                następuje poprzez zaznaczenie takiej opcji pod formularzem. Dane
                osobowe związane z zapytaniem są przez nas przetwarzane przez
                okres niezbędny do udzielenia odpowiedzi na zapytanie. Dane te
                nie są przekazywane podmiotom trzecim. <br />
                Zgoda na przetwarzanie danych osobowych w celach związanych ze
                złożonym zapytaniem może zostać cofnięta w każdym czasie.
                Cofnięcie zgody następuje poprzez przesłanie wiadomości e-mail
                na adres: sprzedaz@slowackiego7.pl. W razie cofnięcia zgody,
                Administrator niezwłocznie zaprzestanie dalszego przetwarzania
                danych osobowych, co może jednak skutkować brakiem udzielenia
                odpowiedzi na skierowane zapytanie. Cofnięcie zgody nie wpływa
                na zgodność z prawem przetwarzania dokonanego przed cofnięciem
                zgody.
              </div>
              <div className="policy__text">
                <b className="d-block">
                  E. Udostępnianie danych. Przekazywanie danych do państw
                  trzecich.
                </b>
                Dostęp do danych osobowych użytkowników ma Administrator, jego
                upoważnieni pracownicy oraz podmioty którym Administrator
                powierzy ich przetwarzanie, w tym podmioty z którymi
                Administrator współpracuje w zakresie obsługi systemów
                informatycznych, serwisów poczty elektronicznej i utrzymania
                Strony. <br />
                Administrator nie przekazuje danych pozwalających na
                identyfikację użytkowników do państw spoza Europejskiego Obszaru
                Gospodarczego. Dane użytkowników mogą być jednak pozyskiwane i
                przetwarzane przez podmioty znajdujące się z państwach spoza EOG
                w przypadku korzystania przez Użytkowników z portali
                społecznościowych których współadministratorami będą podmioty
                mające siedzibę poza EOG.
              </div>
              <div className="policy__text">
                <b className="d-block">F. Profilowanie</b>
                Dane Użytkowników korzystających ze strony mogą podlegać
                profilowaniu w celach związanych z marketingiem usług i
                produktów Administratora opisanych w części C, w tym poprzez
                reklamy spersonalizowane i remarketing.
              </div>
            </div>

            <div className="col-12 col-xxl-10">
              <h3 className="policy__text-title">
                3. Uprawnienia Użytkownika związane z przetwarzaniem danych
                osobowych.
              </h3>
              <div className="policy__text">
                Użytkownikom przysługuje prawo dostępu do danych oraz żądana
                sprostowania nieprawidłowych danych. <br />W razie
                zakwestionowania prawidłowości przetwarzanych danych Użytkownik
                może domagać się ograniczenia ich przetwarzania – na okres
                pozwalający na sprawdzenie prawidłowość tych danych. <br />
                Gdy dane nie będą już potrzebne w celach w jakich zostały
                zebrane lub były przetwarzane, jak również w razie cofnięcia
                zgody na ich przetwarzanie, Użytkownik może żądać ich usunięcia,
                albo ograniczenia ich przetwarzania (jeżeli będą Użytkownikowi
                potrzebne do ustalenia, dochodzenia lub obrony roszczeń). <br />
                W przypadku gdy dane będą przetwarzane na podstawie prawnie
                uzasadnionego interesu Administratora, użytkownikom przysługuje
                prawo wniesienia sprzeciwu wobec przetwarzania ich danych w tym
                profilowania. W takim przypadku Administratorowi nie wolno już
                przetwarzać tych danych osobowych, chyba że wykaże on istnienie
                ważnych prawnie uzasadnionych podstaw do przetwarzania,
                nadrzędnych wobec interesów, praw i wolności użytkowników, lub
                podstaw do ustalenia, dochodzenia lub obrony roszczeń. Powyższe
                ograniczenie nie dotyczy danych wykorzystywanych do marketingu
                bezpośredniego, których po wniesieniu sprzeciwu nie wolno już
                przetwarzać do tego celu. <br />
                W przypadku stwierdzenia, że przetwarzanie jest niezgodne z
                prawem, Użytkownik może zażądać ograniczenia przetwarzania
                danych osobowych lub ich usunięcia. <br />
                Użytkownikom przysługuje prawo otrzymania danych, które zostały
                nam przekazane, w ustrukturyzowanym, powszechnie używanym
                formacie, nadającym się do odczytu maszynowego. Dane te
                użytkownik może przekazać wybranemu przez siebie
                administratorowi. Może też, jeśli będzie to technicznie możliwe,
                żądać by zostały one przez nas bezpośrednio przesłane innemu
                administratorowi. <br />
                Uprawnienia o których mowa powyżej można wykonywać przesyłając
                wiadomość na adres poczty elektronicznej:
                sprzedaz@slowackiego7.pl <br />W razie uznania, że przetwarzanie
                danych osobowych naruszać będzie przepisy RODO, Użytkownik może
                wnieść skargę do organu nadzorczego – Prezesa Urzędu Ochrony
                Danych Osobowych.
              </div>
            </div>

            <div className="col-12 col-xxl-10">
              <h3 className="policy__text-title">4. Bezpieczeństwo danych</h3>
              <div className="policy__text">
                Przywiązujemy duża wagę do ochrony danych osobowych Użytkowników
                oraz innych informacji jakie są przez nas pozyskiwane w związku
                z korzystaniem ze Strony. Stosujemy rozwiązania techniczne i
                organizacyjne w celu zapewnienia integralności, dostępności i
                poufności danych. Dostęp do danych mają jedynie osoby
                upoważnione i jedynie w zakresie, w jakim jest to niezbędne ze
                względu na zakres ich obowiązków. Stosowane przez nas
                rozwiązania są na bieżąco monitorowane, a w razie koniczności
                wdrażane są dodatkowe zabezpieczenia. <br />W przypadku
                powierzenia przetwarzania danych osobowych podmiotom trzecim
                powierzamy ich przetwarzanie wyłącznie takim podmiotom, które
                zapewniają wystarczające gwarancje wdrożenia odpowiednich
                środków technicznych i organizacyjnych, by przetwarzanie
                spełniało wymogi RODO i chroniło prawa Użytkowników. Powierzenie
                przetwarzania danych następuje na podstawie umowy.
              </div>
            </div>

            <div className="col-12 col-xxl-10">
              <h3 className="policy__text-title">5. Obsługa Użytkowników</h3>
              <div className="policy__text">
                Pytania lub wątpliwości odnoszące się do niniejszej Polityki
                prywatności oraz dotyczące przetwarzania danych osobowych można
                przesłać na adres poczty elektronicznej:
                sprzedaz@slowackiego7.pl , lub na adres korespondencyjny
                Administratora: ul. Skaryszewska 7, 03-802 Warszawa.
              </div>
            </div>

            <div className="col-12 col-xxl-10">
              <h3 className="policy__text-title">
                6. Zmiany Polityki Prywatności
              </h3>
              <div className="policy__text">
                Niniejszy dokument dotyczy wyłącznie korzystania ze Strony. Nie
                odnosi się on do stron innych dostawców usług, do których na
                naszej stronie mogą zostać zamieszczone odesłania. Nie ponosimy
                odpowiedzialności za działania innych podmiotów, nie związane z
                naszą stroną. <br />
                Niniejsza polityka prywatności może ulegać zmianom, stosownie do
                zmian dotyczących pozyskiwania i przetwarzania danych związanych
                z funkcjonowaniem naszych stron internetowych.
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Policy;
