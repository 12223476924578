export const Sketch = () => {
  return (
    <>
      <section className="sketch" id="sketch">
        <div className="container">
          <div
            className="sketch__box image-bg d-none d-md-block"
            style={{
              backgroundImage: `url(${require("../assets/images/sketch2.png")})`,
            }}
          >
            <div className="container d-none d-xl-block">
              <div className="row">
                <div className="col-6">
                  <div className="sketch__box-text">
                    <p>
                      Architektura obiektu łączy w sobie cechy architektury
                      współczesnej widocznej przy ulicy Roosevelta oraz
                      klasycznej. Rozdrobnienie i artykulacja elewacji, podział
                      na mniejsze bryły, podkreślanie partii cokołowej i
                      dachowej to zabiegi, które – mimo współczesnej formy –
                      mają szanować historyczną zabudowę.
                    </p>
                    <p className="mb-0">
                      <b>Szymon Januszewski</b>,<br /> z pracowni
                      architektonicznej Insomia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="sketch__box image-bg"
            style={{
              backgroundImage: `url(${require("../assets/images/sketch3.png")})`,
            }}
            hidden-md={"true"}
          >
            <div className="container d-none d-xl-block">
              <div className="row">
                <div className="col-6">
                  <div className="sketch__box-text">
                    <p>
                      Architektura obiektu łączy w sobie cechy architektury
                      współczesnej widocznej przy ulicy Roosevelta oraz
                      klasycznej. Rozdrobnienie i artykulacja elewacji, podział
                      na mniejsze bryły, podkreślanie partii cokołowej i
                      dachowej to zabiegi, które – mimo współczesnej formy –
                      mają szanować historyczną zabudowę.
                    </p>
                    <p className="mb-0">
                      <b>Szymon Januszewski</b>,<br /> z pracowni
                      architektonicznej Insomia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container" hidden-xl={"true"}>
            <div className="row">
              <div className="col-12">
                <div className="sketch__box-text">
                  <p>
                    Architektura obiektu łączy w sobie cechy architektury
                    współczesnej widocznej przy ulicy Roosevelta oraz
                    klasycznej. Rozdrobnienie i artykulacja elewacji, podział na
                    mniejsze bryły, podkreślanie partii cokołowej i dachowej to
                    zabiegi, które – mimo współczesnej formy – mają szanować
                    historyczną zabudowę.
                  </p>
                  <p className="mb-0">
                    <b>Szymon Januszewski</b>,<br /> z pracowni
                    architektonicznej Insomia.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sketch;
