import React from "react";

class FormAsk extends React.Component {
   state = {
      name: "",
      nameVal: "",
      surnameVal: "",
      email: "",
      phone: "",
      message: "",
      agreement: {
         agr1: false,
         agr2: false,
      },
      mailSent: false,
   };

   handleNameChange = (e) => {
      let values = e.target.value.split(" ");

      if (values[0] === undefined) {
         values[0] = "";
      }

      if (values[1] === undefined) {
         values[1] = "";
      }

      this.setState({
         name: e.target.value,
         nameVal: values[0],
         surnameVal: values[1],
      });
   };

   handleEmailChange = (e) => {
      this.setState({
         email: e.target.value,
      });
   };

   handlePhoneChange = (e) => {
      if (isNaN(e.target.value) && e.target.value !== "") {
         return (e.target.value = this.state.phone);
      }
      this.setState({
         phone: e.target.value,
      });
   };

   handleMessageChange = (e) => {
      this.setState({
         message: e.target.value,
      });
   };

   handleAgreementChange = (e) => {
      const agreement = this.state.agreement;

      switch (e.target.name) {
         case "agreement1":
            agreement.agr1 = !agreement.agr1;
            this.setState({
               agreement,
            });
            break;

         case "agreement2":
            agreement.agr2 = !agreement.agr2;
            this.setState({
               agreement,
            });
            break;

         default:
            break;
      }
   };

   handleFormSubmit = (e) => {
      e.preventDefault();
      let validationPassed = true;

      const re =
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      const isMail = re.test(String(this.state.email).toLowerCase());

      if (this.state.nameVal.length < 3 || this.state.surnameVal.length < 3) {
         validationPassed = false;
         e.target.name.parentNode.classList.add("err");
      } else {
         e.target.name.parentNode.classList.remove("err");
      }

      if (!isMail) {
         validationPassed = false;
         e.target.email.parentNode.classList.add("err");
      } else {
         e.target.email.parentNode.classList.remove("err");
      }

      if (this.state.phone.length < 9) {
         validationPassed = false;
         e.target.phone.parentNode.classList.add("err");
      } else {
         e.target.phone.parentNode.classList.remove("err");
      }

      if (this.state.message.length <= 0) {
         validationPassed = false;
         e.target.message.parentNode.classList.add("err");
      } else {
         e.target.message.parentNode.classList.remove("err");
      }

      if (
         this.state.agreement.agr1 === false ||
         this.state.agreement.agr2 === false
      ) {
         validationPassed = false;
         document.querySelector(".form__check-info").classList.add("err");
      } else {
         document.querySelector(".form__check-info").classList.remove("err");
      }

      if (validationPassed) {
         this.sendMail(e.target);
      }

      // action="https://proxin.thtg.pl/api/v1/public/client"
   };

   sendMail = (form) => {
      let formData = new URLSearchParams();

      formData.append("name", this.state.nameVal);
      formData.append("surname", this.state.surnameVal);
      formData.append("email", this.state.email);
      formData.append("phone", this.state.phone);
      formData.append(
         "message",
         `Zapytanie ofertowe dla lokalu ${this.props.data.number}\r` +
            this.state.message
      );
      formData.append("source", "www");
      formData.append("sourceDetails", "http://slowackiego7.pl");

      const formDataStr = formData.toString();

      let url = `https://proxin.thtg.pl/api/v1/public/client?`;

      url = url + formDataStr;

      console.log(formDataStr);

      fetch(url, {
         method: "GET", // or 'PUT'
      })
         .then((response) => {
            if (response.ok) {
               return response.json();
            }
            throw Error(response.status);
         })
         .then((result) => {
            this.setState({
               mailSent: true,
            });
         })
         .catch((error) => console.log(error + " error"));
   };

   render() {
      return (
         <>
            <section
               className={`form form--offer ${
                  this.props.isActive ? "active" : ""
               }`}
               id="form"
               onClick={this.props.toggle}
            >
               {this.state.mailSent ? (
                  <div className="form-box">
                     <p className="form-box__title">
                        Dziękujemy za wysłanie formularza
                     </p>
                  </div>
               ) : (
                  <div className="form-box">
                     <p className="form-box__title">
                        Zapytanie ofertowe dla lokalu
                        <span>{this.props.data.number}</span>
                     </p>
                     <form onSubmit={this.handleFormSubmit}>
                        <label
                           className="form__input-box form__name"
                           data-name="name"
                        >
                           <input
                              type="text"
                              name="nameVal"
                              id="nameVal"
                              autoComplete="off"
                              maxLength="50"
                              value={this.state.name}
                              onFocus={(e) =>
                                 e.target
                                    .closest(".form__input-box")
                                    .classList.add("focus")
                              }
                              onBlur={(e) =>
                                 e.target.value.length <= 0 &&
                                 e.target
                                    .closest(".form__input-box")
                                    .classList.remove("focus")
                              }
                              onChange={this.handleNameChange}
                           />
                           <input
                              type="hidden"
                              name="name"
                              id="name"
                              value={this.state.nameVal}
                           />
                           <input
                              type="hidden"
                              name="surname"
                              id="surname"
                              value={this.state.surnameVal}
                           />
                        </label>
                        <label
                           className="form__input-box form__email"
                           data-name="email"
                        >
                           <input
                              type="text"
                              name="email"
                              id="email"
                              autoComplete="off"
                              value={this.state.email}
                              onFocus={(e) =>
                                 e.target
                                    .closest(".form__input-box")
                                    .classList.add("focus")
                              }
                              onBlur={(e) =>
                                 e.target.value.length <= 0 &&
                                 e.target
                                    .closest(".form__input-box")
                                    .classList.remove("focus")
                              }
                              onChange={this.handleEmailChange}
                           />
                        </label>
                        <label
                           className="form__input-box form__phone"
                           data-name="phone"
                        >
                           <input
                              type="text"
                              name="phone"
                              id="phone"
                              autoComplete="off"
                              maxLength="9"
                              value={this.state.phone}
                              onFocus={(e) =>
                                 e.target
                                    .closest(".form__input-box")
                                    .classList.add("focus")
                              }
                              onBlur={(e) =>
                                 e.target.value.length <= 0 &&
                                 e.target
                                    .closest(".form__input-box")
                                    .classList.remove("focus")
                              }
                              onChange={this.handlePhoneChange}
                           />
                        </label>
                        <label
                           className="form__input-box form__question"
                           data-name="question"
                        >
                           <textarea
                              type="text"
                              name="message"
                              id="message"
                              autoComplete="off"
                              maxLength="355"
                              value={this.state.message}
                              onFocus={(e) =>
                                 e.target
                                    .closest(".form__input-box")
                                    .classList.add("focus")
                              }
                              onBlur={(e) =>
                                 e.target.value.length <= 0 &&
                                 e.target
                                    .closest(".form__input-box")
                                    .classList.remove("focus")
                              }
                              onChange={this.handleMessageChange}
                           />
                        </label>
                        <input type="hidden" name="source" value="www" />
                        <input
                           type="hidden"
                           name="sourceDetails"
                           value="http://slowackiego7.pl"
                        />
                        <div className="form__check">
                           <div className="form__check-item">
                              <input
                                 type="checkbox"
                                 name="agreement1"
                                 id="agreement1"
                                 className="form__agreement1"
                                 checked={this.state.agreement.agr1}
                                 onChange={this.handleAgreementChange}
                              />
                              <label
                                 className="form__check-item-text"
                                 htmlFor="agreement1"
                              >
                                 Oświadczam, że zapoznałem się z informacjami o
                                 przetwarzaniu moich danych osobowych zawartymi
                                 w Polityce prywatności *
                              </label>
                           </div>
                           <div className="form__check-item">
                              <input
                                 type="checkbox"
                                 name="agreement2"
                                 id="agreement2"
                                 className="form__agreement2"
                                 checked={this.state.agreement.agr2}
                                 onChange={this.handleAgreementChange}
                              />
                              <label
                                 className="form__check-item-text"
                                 htmlFor="agreement2"
                              >
                                 Wyrażam zgodę na przetwarzanie moich danych
                                 osobowych podanych w powyższym formularzu przez
                                 Proxin Słowackiego spółka z ograniczoną
                                 odpowiedzialnością z siedzibą w Warszawie, nr
                                 KRS 0000833935, w celu nawiązania kontaktu i
                                 udzielenia odpowiedzi na pytania zawarte w
                                 formularzu kontaktowym.*
                              </label>
                           </div>
                        </div>
                        <p className="form__check-info">
                           *zgody wymagane do skorzystania z formularza
                           kontaktowego
                        </p>
                        <div className="form__button">
                           <button type="submit" name="submit" value="">
                              wyślij
                           </button>
                        </div>
                     </form>
                  </div>
               )}
            </section>
         </>
      );
   }
}

export default FormAsk;
