import React from "react";

class Form extends React.Component {
   state = {
      name: "",
      email: "",
      phone: "",
      question: "",
      agreement: {
         agr1: false,
         agr2: false,
      },
   };

   handleNameChange = (e) => {
      this.setState({
         name: e.target.value,
      });
   };

   handleEmailChange = (e) => {
      this.setState({
         email: e.target.value,
      });
   };

   handlePhoneChange = (e) => {
      if (isNaN(e.target.value) && e.target.value !== "") {
         return (e.target.value = this.state.phone);
      }
      this.setState({
         phone: e.target.value,
      });
   };

   handleQuestionChange = (e) => {
      this.setState({
         question: e.target.value,
      });
   };

   handleAgreementChange = (e) => {
      const agreement = this.state.agreement;

      switch (e.target.name) {
         case "agreement1":
            agreement.agr1 = !agreement.agr1;
            this.setState({
               agreement,
            });
            break;

         case "agreement2":
            agreement.agr2 = !agreement.agr2;
            this.setState({
               agreement,
            });
            break;

         default:
            break;
      }
   };

   handleFormSubmit = (e) => {
      let validationPassed = true;

      const re =
         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      const isMail = re.test(String(this.state.email).toLowerCase());

      if (this.state.name.length < 3) {
         validationPassed = false;
         e.target.name.parentNode.classList.add("err");
      } else {
         e.target.name.parentNode.classList.remove("err");
      }

      if (!isMail) {
         validationPassed = false;
         e.target.email.parentNode.classList.add("err");
      } else {
         e.target.email.parentNode.classList.remove("err");
      }

      if (this.state.phone.length < 9) {
         validationPassed = false;
         e.target.phone.parentNode.classList.add("err");
      } else {
         e.target.phone.parentNode.classList.remove("err");
      }

      if (this.state.question.length <= 0) {
         validationPassed = false;
         e.target.question.parentNode.classList.add("err");
      } else {
         e.target.question.parentNode.classList.remove("err");
      }

      if (
         this.state.agreement.agr1 === false ||
         this.state.agreement.agr2 === false
      ) {
         validationPassed = false;
         document.querySelector(".form__check-info").classList.add("err");
      } else {
         document.querySelector(".form__check-info").classList.remove("err");
      }

      if (!validationPassed) {
         e.preventDefault();
      }
   };

   render() {
      return (
         <>
            <section className="form section--with-anim" id="form">
               <div className="container">
                  <div className="row">
                     <div className="col-12">
                        <form
                           action="./form/contact_form.php"
                           method="POST"
                           onSubmit={this.handleFormSubmit}
                        >
                           <label
                              className="form__input-box form__name"
                              data-name="name"
                           >
                              <input
                                 type="text"
                                 name="name"
                                 id="name"
                                 autoComplete="off"
                                 maxLength="50"
                                 value={this.state.name}
                                 onFocus={(e) =>
                                    e.target
                                       .closest(".form__input-box")
                                       .classList.add("focus")
                                 }
                                 onBlur={(e) =>
                                    e.target.value.length <= 0 &&
                                    e.target
                                       .closest(".form__input-box")
                                       .classList.remove("focus")
                                 }
                                 onChange={this.handleNameChange}
                              />
                           </label>
                           <label
                              className="form__input-box form__email"
                              data-name="email"
                           >
                              <input
                                 type="text"
                                 name="email"
                                 id="email"
                                 autoComplete="off"
                                 value={this.state.email}
                                 onFocus={(e) =>
                                    e.target
                                       .closest(".form__input-box")
                                       .classList.add("focus")
                                 }
                                 onBlur={(e) =>
                                    e.target.value.length <= 0 &&
                                    e.target
                                       .closest(".form__input-box")
                                       .classList.remove("focus")
                                 }
                                 onChange={this.handleEmailChange}
                              />
                           </label>
                           <label
                              className="form__input-box form__phone"
                              data-name="phone"
                           >
                              <input
                                 type="text"
                                 name="phone"
                                 id="phone"
                                 autoComplete="off"
                                 maxLength="9"
                                 value={this.state.phone}
                                 onFocus={(e) =>
                                    e.target
                                       .closest(".form__input-box")
                                       .classList.add("focus")
                                 }
                                 onBlur={(e) =>
                                    e.target.value.length <= 0 &&
                                    e.target
                                       .closest(".form__input-box")
                                       .classList.remove("focus")
                                 }
                                 onChange={this.handlePhoneChange}
                              />
                           </label>
                           <label
                              className="form__input-box form__question"
                              data-name="question"
                           >
                              <input
                                 type="text"
                                 name="question"
                                 id="question"
                                 autoComplete="off"
                                 maxLength="255"
                                 value={this.state.question}
                                 onFocus={(e) =>
                                    e.target
                                       .closest(".form__input-box")
                                       .classList.add("focus")
                                 }
                                 onBlur={(e) =>
                                    e.target.value.length <= 0 &&
                                    e.target
                                       .closest(".form__input-box")
                                       .classList.remove("focus")
                                 }
                                 onChange={this.handleQuestionChange}
                              />
                           </label>
                           <div className="form__check">
                              <div className="form__check-item">
                                 <input
                                    type="checkbox"
                                    name="agreement1"
                                    id="agreement1"
                                    className="form__agreement1"
                                    checked={this.state.agreement.agr1}
                                    onChange={this.handleAgreementChange}
                                 />
                                 <label
                                    className="form__check-item-text"
                                    htmlFor="agreement1"
                                 >
                                    Oświadczam, że zapoznałem się z informacjami
                                    o przetwarzaniu moich danych osobowych
                                    zawartymi w Polityce prywatności *
                                 </label>
                              </div>
                              <div className="form__check-item">
                                 <input
                                    type="checkbox"
                                    name="agreement2"
                                    id="agreement2"
                                    className="form__agreement2"
                                    checked={this.state.agreement.agr2}
                                    onChange={this.handleAgreementChange}
                                 />
                                 <label
                                    className="form__check-item-text"
                                    htmlFor="agreement2"
                                 >
                                    Wyrażam zgodę na przetwarzanie moich danych
                                    osobowych podanych w powyższym formularzu
                                    przez Proxin Słowackiego spółka z
                                    ograniczoną odpowiedzialnością z siedzibą w
                                    Warszawie, nr KRS 0000833935, w celu
                                    nawiązania kontaktu i udzielenia odpowiedzi
                                    na pytania zawarte w formularzu
                                    kontaktowym.*
                                 </label>
                              </div>
                           </div>
                           <p className="form__check-info">
                              *zgody wymagane do skorzystania z formularza
                              kontaktowego
                           </p>
                           <div className="form__button">
                              <button type="submit" name="submit">
                                 wyślij
                              </button>
                           </div>
                        </form>
                     </div>
                  </div>
                  <div className="row" hidden-xl="true">
                     <div className="col-12">
                        <div className="form__call-us">
                           <p className="form__call-us-text-primary">
                              Zadzwoń do nas
                           </p>
                           <p className="form__call-us-text-secondary">
                              +48 603 711 805
                           </p>
                           <p className="form__call-us-text-secondary mb-0">
                              +48 663 940 530
                           </p>
                           {/* <p className="form__call-us-text-secondary mb-0">
                              +48 603 711 305
                           </p> */}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </>
      );
   }
}

export default Form;
