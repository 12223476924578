import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import FloorProjection from "../components/FloorProjection";
import OfferFilter from "../components/OfferFilter";
import OfferResult from "../components/OfferResult";

export const Offer = (props) => {
  const [menu, setMenu] = useState(false);

  const [flats, setFlats] = useState(null);

  const [limit, setLimit] = useState(3);

  useEffect(() => {
    // props.appLoadFun();
    props.reset();
    props.appLoadFun();
    window.scrollTo(0, 0);
    return () => {
      props.reset();
      props.appLoadFun();
    };
  }, [props]);

  const toggleMenu = () => {
    !menu
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style = "");
    setMenu(!menu);
  };

  const filterFlats = (data, amounts) => {
    if (!props.data) return;

    window.scrollTo(0, document.getElementById("offer-results").offsetTop - 90);

    // if (data.empty) return filterFlatsLoad();

    let flt = props.data;

    flt = flt.filter((item) => {
      if (
        item.state === "available" ||
        item.state === "reservation" ||
        item.state === "preReservation" ||
        item.state === "sold"
      ) {
        return item;
      } else return null;
    });

    // kolejność [floor,rooms,status]

    let filterData = [false, false, false];

    if (
      data.floor.flr0 ||
      data.floor.flr1 ||
      data.floor.flr2 ||
      data.floor.flr3 ||
      data.floor.flr4 ||
      data.floor.flr5
    ) {
      filterData[0] = true;
    }

    if (data.rooms.room1 || data.rooms.room2 || data.rooms.room3) {
      filterData[1] = true;
    }

    if (data.status.free || data.status.reserved || data.status.sold) {
      filterData[2] = true;
    }

    if (filterData[0]) {
      flt = flt.filter((item) => {
        if (
          (item.floor === 0 && data.floor.flr0) ||
          (item.floor === 1 && data.floor.flr1) ||
          (item.floor === 2 && data.floor.flr2) ||
          (item.floor === 3 && data.floor.flr3) ||
          (item.floor === 4 && data.floor.flr4) ||
          (item.floor === 5 && data.floor.flr5)
        ) {
          return item;
        } else return null;
      });
    }
    if (filterData[1]) {
      flt = flt.filter((item) => {
        if (
          (item.roomsNumber === 1 && data.rooms.room1) ||
          (item.roomsNumber === 2 && data.rooms.room2) ||
          (item.roomsNumber === 3 && data.rooms.room3)
        ) {
          return item;
        } else return null;
      });
    }
    if (filterData[2]) {
      flt = flt.filter((item) => {
        if (
          (item.state === "available" && data.status.free) ||
          (item.state === "reservation" && data.status.reserved) ||
          (item.state === "preReservation" && data.status.reserved) ||
          (item.state === "sold" && data.status.sold)
        ) {
          return item;
        } else return null;
      });
    }

    if (amounts.prices.min !== 0) {
      flt = flt.filter((item) => {
        if (
          item.priceOffer.areaBrutto > amounts.prices.min &&
          item.priceOffer.areaBrutto < amounts.prices.max
        ) {
          return item;
        } else return null;
      });
    }

    if (amounts.area.min !== 0) {
      flt = flt.filter((item) => {
        if (
          item.area > Number(amounts.area.min) &&
          Number(item.area < amounts.area.max)
        ) {
          return item;
        } else return null;
      });
    }

    setFlats(flt);
  };

  const filterFlatsLoad = () => {
    let flt;

    flt = props.data.filter((item) => {
      if (
        item.state === "available" ||
        item.state === "reservation" ||
        item.state === "preReservation" ||
        item.state === "sold"
      ) {
        return item;
      } else return null;
    });

    setFlats(flt);
  };

  const scrollTo = (location) => {
    // document.getElementById(location).scrollIntoView();
    const el = document.getElementById(location).offsetTop;
    window.scrollTo({ top: el - 95, behavior: "smooth" });
    if (window.matchMedia("(max-width:767px)").matches) {
      toggleMenu();
    }
  };

  if (props.data && !flats) filterFlatsLoad();

  return (
    <>
      <div className="floor-projection__item-image-flat-info">
        <p>
          <b>
            <span></span>
            <span></span>
          </b>
        </p>
        <p>
          powierzchnia:
          <b>
            <span></span> m<sup>2</sup>
          </b>
        </p>
        <p>
          pokoje:
          <b>
            <span></span>
          </b>
        </p>
        <p>
          cena:
          <b>
            <span></span> zł
          </b>
        </p>
        <p className="promo">
          Cena promocyjna:
          <b>
            <span>11 500</span> zł/m<sup>2</sup> netto
          </b>
        </p>
      </div>
      <header className="header header--offer d-xl-flex">
        <div className="header__left-col" hidden-xl="true">
          <div className="header__menu">
            <div className={`header__menu-list${menu ? " active" : ""}`}>
              <span className="header__menu-list-close" onClick={toggleMenu}>
                X
              </span>
              <ul className="list-unstyled">
                <li>
                  <NavLink to="/">Strona główna</NavLink>
                </li>
                <li>
                  <NavLink to="/oferta">Oferta</NavLink>
                </li>
                <li onClick={() => scrollTo("form")}>Kontakt</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="header__right-col">
          <div
            className="header__top"
            style={{
              backgroundImage: `url(${require("../assets/images/texture.jpg")})`,
            }}
          >
            <div
              className="header__top-mobile-menu-logo"
              style={{
                backgroundImage: `url(${
                  require("../assets/images/icons/logo-s7.svg").default
                })`,
              }}
            ></div>
            <div className="header__top-menu d-none d-xl-flex">
              <ul>
                <li>
                  <NavLink to="/">Strona główna</NavLink>
                </li>
                <li>
                  <NavLink to="/oferta">Oferta</NavLink>
                </li>
                <li onClick={() => scrollTo("form")}>Kontakt</li>
              </ul>
              <div>
                Biuro sprzedaży:
                <b>61 671 18 05</b>
              </div>
            </div>
            <div className="header__top-mobile-menu-icon" onClick={toggleMenu}>
              <span></span>
            </div>
          </div>
          <div className="header__banner header__banner--offer">
            <div className="container">
              <div className="header__banner-text">Wybierz nieruchomość</div>
              {flats && <FloorProjection data={flats} />}
            </div>
          </div>
          <div
            className="header__arrows d-xl-none"
            onClick={() =>
              window.scrollTo({
                top: document.getElementById("offer-filter").offsetTop - 90,
                behavior: "smooth",
              })
            }
          >
            <img
              src={require("../assets/images/icons/arrow.svg").default}
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
        <div
          className="header__side-menu d-none d-xl-flex"
          onMouseEnter={() => document.body.classList.add("move")}
          onMouseLeave={() => document.body.classList.remove("move")}
        >
          <div
            className="header__side-menu-logo"
            style={{
              backgroundImage: `url(${
                require("../assets/images/icons/logo-s7.svg").default
              })`,
            }}
          ></div>
          <div className="header__side-menu-icon">
            <span></span>
          </div>
          <div className="header__side-menu-list">
            <div
              className="header__side-menu-list-logo"
              style={{
                backgroundImage: `url(${
                  require("../assets/images/icons/logo-s7.svg").default
                })`,
              }}
            ></div>
            <ul className="list-unstyled">
              <li>
                <NavLink to="/">Strona główna</NavLink>
              </li>
              <li>
                <NavLink to="/oferta">Oferta</NavLink>
              </li>
              <li onClick={() => scrollTo("form")}>Kontakt</li>
            </ul>
            <div className="header__side-menu-info">
              <p>
                <a href="mailto:sprzedaz@slowackiego7.pl">Napisz do nas</a>
                <br /> Zadzwoń :<b className="d-block">61 671 18 05</b>
              </p>
              <a
                href="https://www.facebook.com/Slowackiego7"
                className="header--fb"
              >
                <img
                  src={require("../assets/images/icons/fb.svg").default}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="header__side-menu-info">
            <p>
              <a href="mailto:sprzedaz@slowackiego7.pl">Napisz do nas</a>
              <br /> Zadzwoń :<b className="d-block">61 671 18 05</b>
            </p>
            <a
              href="https://www.facebook.com/Slowackiego7"
              className="header--fb"
            >
              <img
                src={require("../assets/images/icons/fb.svg").default}
                alt=""
              />
            </a>
          </div>
        </div>
      </header>
      <OfferFilter filter={filterFlats} />
      <section className="offer-results" id="offer-results">
        <div className="container container--offer">
          <div className="row">
            <div className="col-12 d-xl-none">
              <div className="info">Ceny lokali usługowych są cenami netto</div>
            </div>
            {flats ? (
              flats.slice(0, limit).map((item) => {
                return <OfferResult key={item.id} data={item} />;
              })
            ) : (
              <div className="offer-loading ps-0">
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <p> Wczytywanie...</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              {flats && flats.length > 3 && (
                <button
                  className="offer-results__show-more"
                  onClick={() => {
                    limit === 3 ? setLimit(props.data.length) : setLimit(3);
                    setTimeout(() => {
                      window.scrollTo({
                        top:
                          document.getElementById("offer-results").offsetTop -
                          90,
                        behavior: "smooth",
                      });
                    }, 100);
                  }}
                >
                  {limit === 3 ? "wczytaj więcej" : "schowaj"}
                </button>
              )}

              {flats && flats.length < 1 && (
                <div className="offer-results__no-results">Brak wyników...</div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Offer;
