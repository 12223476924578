export const ImagesRow = () => {
  return (
    <section className="imagesRow d-none d-xl-flex" id="imagesRow">
      <div
        className="imagesRow__image clickable-image"
        style={{
          backgroundImage: `url(${require("../assets/images/image6.jpg")})`,
        }}
      ></div>
      <div
        className="imagesRow__image clickable-image"
        style={{
          backgroundImage: `url(${require("../assets/images/image3.jpg")})`,
        }}
      ></div>
    </section>
  );
};

export default ImagesRow;
