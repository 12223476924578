export const Flat = () => {
  return (
    <>
      <section className="flat section--with-anim" id="flat">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 m-auto col-xl-4 col--small">
              <h2>MIESZKANIA</h2>
            </div>
            <div className="col-12 col-md-8 m-auto col-xl-8 col--small">
              <div className="app__text-box">
                <div className="line"></div>
                <p className="app__text">
                  Słowackiego 7 to pięcio kondygnacyjny budynek w którym
                  znajduje się 61 mieszkań o powierzchni od <b>25,11</b> do{" "}
                  <b>91,85</b> mkw. Lokalizacja w samym centrum Poznania
                  zapewnia wygodę mieszkania z jednoczesnym korzystaniem z
                  walorów miasta.
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="info-end">
                <img
                  src={require("../assets/images/slowackiego7-telefon.png")}
                  alt=""
                  className="img-fluid"
                />
                <img
                  src={require("../assets/images/slowackiego7-tablet.png")}
                  alt=""
                  className="img-fluid"
                />
                <img
                  src={require("../assets/images/slowackiego7-desktop.png")}
                  alt=""
                  className="img-fluid"
                />
              </div>
              {/* <a
                className="flat__btn"
                href={require("../assets/pdf/slowackiego7_prezentacja.pdf")}
                target="_blank"
                rel="noreferrer"
              >
                Pobierz ofertę
              </a> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Flat;
