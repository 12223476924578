export const Tiles = () => {
  return (
    <>
      <section className="tiles section--with-anim" id="titles">
        <div className="container">
          <div className="tiles__wrapper">
            <div className="tiles__item">
              <div className="tiles__item-image">
                <img
                  src={require("../assets/images/icons/ico-drzewa.svg").default}
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="tiles__item-text">
                przestronne tarasy z&nbsp;drzewami, zielona strefa aktywności
                dla mieszkańców, zielone&nbsp;dachy
              </div>
            </div>
            <div className="tiles__item">
              <div className="tiles__item-image">
                <img
                  src={require("../assets/images/icons/ico-rower.svg").default}
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="tiles__item-text">
                boks rowerowy dla mieszkańców, stacja naprawy rowerów
              </div>
            </div>
            <div className="tiles__item">
              <div className="tiles__item-image">
                <img
                  src={require("../assets/images/icons/ico-mapa.svg").default}
                  alt=""
                  className="img-fluid"
                />
              </div>

              <p className="tiles__item-text">lokale usługowe na parterze</p>
            </div>
            <div className="tiles__item">
              <div className="tiles__item-image">
                <img
                  src={
                    require("../assets/images/icons/ico-energia.svg").default
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="tiles__item-text">
                energooszczędne instalacje oświetlające części wspólne
              </div>
            </div>
            <div className="tiles__item">
              <div className="tiles__item-image">
                <img
                  src={require("../assets/images/icons/ico-tarcza.svg").default}
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="tiles__item-text">wideo domofony</div>
            </div>
            <div className="tiles__item">
              <div className="tiles__item-image">
                <img
                  src={
                    require("../assets/images/icons/ico-wejscie.svg").default
                  }
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="tiles__item-text">
                reprezentacyjne części wspólne
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Tiles;
