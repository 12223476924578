import { useState } from "react";
import FloorsItem from "./Floors/FloorsItem";
import Canvas from "./Floors/Canvas";
import { NavLink } from "react-router-dom";

export const Floors = (props) => {
  const [flats, setFlats] = useState(null);

  const [flrNum, setFlrNum] = useState(1);

  const sortFlat = () => {
    let flt = [[], [], [], [], [], []];

    props.data.forEach((item) => {
      if (item.floor === 0) {
        flt[item.floor].push(item);
      }
      if (item.floor === 1) {
        flt[item.floor].push(item);
      }
      if (item.floor === 2) {
        flt[item.floor].push(item);
      }
      if (item.floor === 3) {
        flt[item.floor].push(item);
      }
      if (item.floor === 4) {
        flt[item.floor].push(item);
      }
      if (item.floor === 5) {
        flt[item.floor].push(item);
      }
    });

    setFlats(flt);
  };

  const flrSet = (num) => {
    setFlrNum(num);
  };

  if (props.data && !flats) sortFlat();

  return (
    <>
      <section className="floors" id="floors">
        <div className="container">
          <h3 className="floors__title">
            Wybierz piętro na grafice i przejdź do wyszukiwarki lokali
          </h3>
          <div
            className="floors__image floors__building image-bg"
            style={{
              backgroundImage: `url(${require("../assets/images/sketch1.png")})`,
            }}
          >
            {flats &&
              flats.map((item, index) => {
                return <FloorsItem key={index} data={item} />;
              })}
            {flats && <Canvas flrSet={flrSet} />}

            <NavLink
              to={`/oferta?floor=${flrNum}`}
              hidden
              className="floor-home-btn"
            ></NavLink>
          </div>
        </div>
      </section>
    </>
  );
};

export default Floors;
