import React from "react";

class Header extends React.Component {
  state = {
    menu: false,
  };

  toggleMenu = () => {
    !this.state.menu
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style = "");
    this.setState({
      menu: !this.state.menu,
    });
  };

  scrollTo = (location) => {
    // document.getElementById(location).scrollIntoView();
    const el = document.getElementById(location).offsetTop;
    window.scrollTo({ top: el - 45, behavior: "smooth" });
    if (window.matchMedia("(max-width:767px)").matches) {
      !this.state.menu
        ? (document.body.style.overflowY = "hidden")
        : (document.body.style = "");
      this.setState({
        menu: !this.state.menu,
      });
    }
  };

  render() {
    return (
      <>
        <header className="header d-xl-flex">
          <div className="header__left-col">
            <div className="header__menu">
              <div
                className="header__menu-logo d-none d-xl-block"
                style={{
                  backgroundImage: `url(${
                    require("../assets/images/icons/no.svg").default
                  })`,
                }}
              ></div>
              <div
                className={`header__menu-list${
                  this.state.menu ? " active" : ""
                }`}
              >
                <span
                  className="header__menu-list-close"
                  onClick={this.toggleMenu}
                >
                  X
                </span>
                <ul className="list-unstyled">
                  <li onClick={() => this.scrollTo("location")}>LOKALIZACJA</li>
                  <li onClick={() => this.scrollTo("investment")}>
                    INWESTYCJA
                  </li>
                  <ul className="list-unstyled">
                    <li onClick={() => this.scrollTo("standard")}>STANDARD</li>
                    <li onClick={() => this.scrollTo("investor")}>INWESTOR</li>
                  </ul>
                  <li onClick={() => this.scrollTo("floors")}>MIESZKANIA</li>
                  <li onClick={() => this.scrollTo("hello")}>HELLO</li>
                </ul>
              </div>

              <div className="header__menu-contact d-none d-xl-block">
                <p>
                  <a href="mailto:sprzedaz@slowackiego7.pl">Napisz do nas</a>
                  <br /> Zadzwoń:
                  <b className="d-block">61 671 18 05</b>
                </p>
                <a
                  href="https://www.facebook.com/Slowackiego7"
                  className="header--fb"
                >
                  <img
                    src={require("../assets/images/icons/fb.svg").default}
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="header__right-col">
            <div
              className="header__top"
              style={{
                backgroundImage: `url(${require("../assets/images/texture.jpg")})`,
              }}
            >
              <div
                className="header__top-mobile-menu-logo"
                style={{
                  backgroundImage: `url(${
                    require("../assets/images/icons/logo-s7.svg").default
                  })`,
                }}
                hidden-xl={"true"}
              ></div>
              <div
                className="header__top-mobile-menu-icon"
                onClick={this.toggleMenu}
              >
                <span></span>
              </div>
            </div>
            <div className="header__banner">
              <div className="header__banner-img">
                <img
                  src={require("../assets/images/logo.png")}
                  alt=""
                  className="img-fluid d-xl-none"
                />
                <img
                  src={require("../assets/images/icons/logo.svg").default}
                  alt=""
                  className="img-fluid d-none d-xl-block"
                />
              </div>
            </div>
            <div
              className="header__arrows d-xl-none"
              onClick={() =>
                window.scrollTo({
                  top: document.getElementById("location").offsetTop - 45,
                  behavior: "smooth",
                })
              }
            >
              <img
                src={require("../assets/images/icons/arrow.svg").default}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div
            className="header__side-menu hidden d-none d-xl-flex"
            onMouseEnter={() => document.body.classList.add("move")}
            onMouseLeave={() => document.body.classList.remove("move")}
          >
            <div
              className="header__side-menu-logo"
              style={{
                backgroundImage: `url(${
                  require("../assets/images/icons/logo-s7.svg").default
                })`,
              }}
            ></div>
            <div className="header__side-menu-icon">
              <span></span>
            </div>
            <div className="header__side-menu-list">
              <div
                className="header__side-menu-list-logo"
                style={{
                  backgroundImage: `url(${
                    require("../assets/images/icons/logo-s7.svg").default
                  })`,
                }}
              ></div>
              <ul className="list-unstyled">
                <li onClick={() => this.scrollTo("location")}>LOKALIZACJA</li>
                <li onClick={() => this.scrollTo("investment")}>INWESTYCJA</li>
                <ul className="list-unstyled">
                  <li onClick={() => this.scrollTo("standard")}>STANDARD</li>
                  <li onClick={() => this.scrollTo("investor")}>INWESTOR</li>
                </ul>
                <li onClick={() => this.scrollTo("floors")}>MIESZKANIA</li>
                <li onClick={() => this.scrollTo("hello")}>HELLO</li>
              </ul>
              <div className="header__side-menu-info">
                <p>
                  <a href="mailto:sprzedaz@slowackiego7.pl">Napisz do nas</a>
                  <br /> Zadzwoń :<b className="d-block">61 671 18 05</b>
                </p>
                <a
                  href="https://www.facebook.com/Slowackiego7"
                  className="header--fb"
                >
                  <img
                    src={require("../assets/images/icons/fb.svg").default}
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="header__side-menu-info">
              <p>
                <a href="mailto:sprzedaz@slowackiego7.pl">Napisz do nas</a>
                <br /> Zadzwoń :<b className="d-block">61 671 18 05</b>
              </p>
              <a
                href="https://www.facebook.com/Slowackiego7"
                className="header--fb"
              >
                <img
                  src={require("../assets/images/icons/fb.svg").default}
                  alt=""
                />
              </a>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;
