import React from "react";

// export const OfferFilter = () => {

class OfferFilter extends React.Component {
  state = {
    floor: {
      flr0: false,
      flr1: false,
      flr2: false,
      flr3: false,
      flr4: false,
      flr5: false,
    },
    rooms: {
      room1: false,
      room2: false,
      room3: false,
    },
    status: {
      free: false,
      reserved: false,
      sold: false,
    },
    empty: true,
  };

  handleBoxChange = (e) => {
    const floor = this.state.floor;
    const rooms = this.state.rooms;
    const status = this.state.status;

    // const isEmpty = [
    //    ...document.querySelectorAll('.offer-filter input[type="checkbox"]'),
    // ].filter((item) => {
    //    return item.checked;
    // });

    // if (isEmpty.length > 0) {
    //    this.setState({
    //       empty: false,
    //    });
    // } else {
    //    this.setState({
    //       empty: true,
    //    });
    // }

    switch (e.target.name) {
      case "floor-0":
        floor.flr0 = !floor.flr0;
        this.setState({
          floor,
        });
        break;
      case "floor-1":
        floor.flr1 = !floor.flr1;
        this.setState({
          floor,
        });
        break;
      case "floor-2":
        floor.flr2 = !floor.flr2;
        this.setState({
          floor,
        });
        break;

      case "floor-3":
        floor.flr3 = !floor.flr3;
        this.setState({
          floor,
        });
        break;
      case "floor-4":
        floor.flr4 = !floor.flr4;
        this.setState({
          floor,
        });
        break;

      case "floor-5":
        floor.flr5 = !floor.flr5;
        this.setState({
          floor,
        });
        break;
      case "room-1":
        rooms.room1 = !rooms.room1;
        this.setState({
          rooms,
        });
        break;

      case "room-2":
        rooms.room2 = !rooms.room2;
        this.setState({
          rooms,
        });
        break;
      case "room-3":
        rooms.room3 = !rooms.room3;
        this.setState({
          rooms,
        });
        break;

      case "flat-free":
        status.free = !status.free;
        this.setState({
          status,
        });
        break;

      case "flat-reserved":
        status.reserved = !status.reserved;
        this.setState({
          status,
        });
        break;

      case "flat-sold":
        status.sold = !status.sold;
        this.setState({
          status,
        });
        break;

      default:
        break;
    }
  };

  sliderTable = {
    priceMin: 12800,
    priceMax: 15500,
    minArea: 25,
    maxArea: 99.61,
    dotLeft: {
      posX: 0,
      posMin: 0,
      posMax: 0,
    },
    dotRight: {
      posX: 0,
      posMin: 0,
      posMax: 0,
    },
    dot: {
      target: undefined,
      posMin: 0,
      posMax: 0,
    },
    line: {
      wdth: 0,
      pos: 0,
      target: undefined,
    },
    amounts: {
      prices: {
        min: 0,
        max: 0,
      },
      area: {
        min: 0,
        max: 0,
      },
    },
  };

  sliderLoad = () => {
    this.sliderTable.dotLeft.posMin = 0;
    this.sliderTable.dotRight.posMax =
      document.querySelector(".offer-filter__item-slider-line").offsetWidth -
      document.querySelector(".offer-filter__item-slider-dot").offsetWidth;

    this.sliderTable.dotRight.posX =
      document.querySelector(".offer-filter__item-slider-line").offsetWidth -
      document.querySelector(".offer-filter__item-slider-dot").offsetWidth;

    this.sliderTable.line.target = document.querySelector(
      ".offer-filter__item-slider-line-diff"
    );
    this.sliderTable.line.wdth = document.querySelector(
      ".offer-filter__item-slider-line"
    ).offsetWidth;

    this.setLine();
  };

  sliderDotMoveStart = (e) => {
    this.sliderTable.dot.target = e.target;

    this.sliderTable.dotLeft.posX = e.target
      .closest(".offer-filter__item-slider-line")
      .querySelector(".offer-filter__item-slider-dot:nth-child(1)").offsetLeft;

    this.sliderTable.dotRight.posX = e.target
      .closest(".offer-filter__item-slider-line")
      .querySelector(".offer-filter__item-slider-dot:nth-child(3)").offsetLeft;

    this.sliderTable.dotLeft.posMax =
      this.sliderTable.dotRight.posX - e.target.offsetWidth;

    this.sliderTable.dotRight.posMin =
      this.sliderTable.dotLeft.posX + e.target.offsetWidth;

    if (e.target.dataset.pos === "left") {
      this.sliderTable.dot.posMin = this.sliderTable.dotLeft.posMin;
      this.sliderTable.dot.posMax = this.sliderTable.dotLeft.posMax;
    } else if (e.target.dataset.pos === "right") {
      this.sliderTable.dot.posMin = this.sliderTable.dotRight.posMin;
      this.sliderTable.dot.posMax = this.sliderTable.dotRight.posMax;
    }

    document.querySelector("body").classList.add("disable-select");

    window.addEventListener("mousemove", this.sliderDotMove);

    window.addEventListener("mouseup", this.sliderDotMoveEnd);

    window.addEventListener("touchmove", this.sliderDotMove);

    window.addEventListener("touchend", this.sliderDotMoveEnd);
  };

  sliderDotMove = (e) => {
    let mouseType;
    if (e.type === "touchmove") {
      mouseType = e.touches[0].clientX;
    } else {
      mouseType = e.clientX;
    }

    let position =
      mouseType -
      this.sliderTable.dot.target.closest(".offer-filter__item-slider")
        .offsetLeft -
      this.sliderTable.dot.target.offsetWidth / 2;

    if (position < this.sliderTable.dot.posMin) {
      position = this.sliderTable.dot.posMin;
    } else if (position > this.sliderTable.dot.posMax) {
      position = this.sliderTable.dot.posMax;
    }
    this.sliderTable.dot.target.style.left = position + "px";

    if (this.sliderTable.dot.target.dataset.pos === "left") {
      this.sliderTable.dotLeft.posX = this.sliderTable.dot.target.offsetLeft;
    } else if (this.sliderTable.dot.target.dataset.pos === "right") {
      this.sliderTable.dotRight.posX = this.sliderTable.dot.target.offsetLeft;
    }

    this.sliderTable.line.wdth =
      this.sliderTable.dotRight.posX - this.sliderTable.dotLeft.posX;

    this.sliderTable.line.pos = this.sliderTable.dotLeft.posX;

    this.setLine();

    this.setPrices(
      this.sliderTable.dot.target.dataset.pos,
      this.sliderTable.dot.target.closest(".offer-filter__item-slider")
    );
  };

  sliderDotMoveEnd = () => {
    document.querySelector("body").classList.remove("disable-select");

    window.removeEventListener("mouseup", this.sliderDotMoveEnd);
    window.removeEventListener("mousemove", this.sliderDotMove);
    window.removeEventListener("touchend", this.sliderDotMoveEnd);
    window.removeEventListener("touchmove", this.sliderDotMove);
  };

  setLine = () => {
    if (this.sliderTable.dot.target !== undefined) {
      this.sliderTable.line.target = this.sliderTable.dot.target
        .closest(".offer-filter__item-slider-line")
        .querySelector(".offer-filter__item-slider-line-diff");
    }
    this.sliderTable.line.target.style.width =
      this.sliderTable.line.wdth + "px";
    this.sliderTable.line.target.style.left = this.sliderTable.line.pos + "px";
  };

  setPrices = (whichDot, target) => {
    if (target.dataset.type === "area") {
      this.sliderTable.amounts.area.min = (
        ((this.sliderTable.dotLeft.posX /
          (this.sliderTable.dotLeft.posMax +
            this.sliderTable.dot.target.offsetWidth)) *
          100 *
          (this.sliderTable.maxArea - this.sliderTable.minArea)) /
          100 +
        1 * 25
      ).toFixed(2);

      this.sliderTable.amounts.area.max = (
        ((this.sliderTable.dotRight.posX / this.sliderTable.dotRight.posMax) *
          100 *
          (this.sliderTable.maxArea - this.sliderTable.minArea)) /
          100 +
        1 * 25
      ).toFixed(2);

      if (whichDot === "left") {
        target.querySelector(
          ".offer-filter__item-slider-value--left span"
        ).textContent = this.sliderTable.amounts.area.min;
      } else {
        target.querySelector(
          ".offer-filter__item-slider-value--right span"
        ).textContent = this.sliderTable.amounts.area.max;
      }
    } else {
      this.sliderTable.amounts.prices.min =
        ((this.sliderTable.dotLeft.posX /
          (this.sliderTable.dotLeft.posMax +
            this.sliderTable.dot.target.offsetWidth)) *
          100 *
          (this.sliderTable.priceMax - this.sliderTable.priceMin)) /
          100 +
        1 * 12800;

      this.sliderTable.amounts.prices.max =
        ((this.sliderTable.dotRight.posX / this.sliderTable.dotRight.posMax) *
          100 *
          (this.sliderTable.priceMax - this.sliderTable.priceMin)) /
          100 +
        1 * 12800;

      if (whichDot === "left") {
        target.querySelector(
          ".offer-filter__item-slider-value--left span"
        ).textContent = this.sliderTable.amounts.prices.min
          .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      } else {
        target.querySelector(
          ".offer-filter__item-slider-value--right span"
        ).textContent = this.sliderTable.amounts.prices.max
          .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    }
  };

  componentDidMount() {
    this.sliderLoad();
  }

  render() {
    return (
      <>
        <section className="offer-filter bg-green" id="offer-filter">
          <div className="container container--offer">
            <div className="row">
              <div className="col-12 col-sm-4 col-md-3 col-xl-2">
                <div className="offer-filter__item">
                  <p className="offer-filter__item-title">Piętro</p>
                  <div className="offer-filter__item-check">
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="floor-0"
                        id="floor-0"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.floor.flr0}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="floor-0"
                      >
                        Parter
                      </label>
                    </div>
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="floor-1"
                        id="floor-1"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.floor.flr1}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="floor-1"
                      >
                        I piętro
                      </label>
                    </div>
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="floor-2"
                        id="floor-2"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.floor.flr2}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="floor-2"
                      >
                        II piętro
                      </label>
                    </div>
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="floor-3"
                        id="floor-3"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.floor.flr3}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="floor-3"
                      >
                        III piętro
                      </label>
                    </div>
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="floor-4"
                        id="floor-4"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.floor.flr4}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="floor-4"
                      >
                        IV piętro
                      </label>
                    </div>
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="floor-5"
                        id="floor-5"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.floor.flr5}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="floor-5"
                      >
                        V piętro
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-2">
                <div className="offer-filter__item">
                  <p className="offer-filter__item-title">Liczba pokoi</p>
                  <div className="offer-filter__item-check">
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="room-1"
                        id="room-1"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.rooms.room1}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="room-1"
                      >
                        1
                      </label>
                    </div>
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="room-2"
                        id="room-2"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.rooms.room2}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="room-2"
                      >
                        2
                      </label>
                    </div>
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="room-3"
                        id="room-3"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.rooms.room3}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="room-3"
                      >
                        3
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-4 col-md-3">
                <div className="offer-filter__item">
                  <p className="offer-filter__item-title">Status mieszkania</p>
                  <div className="offer-filter__item-check">
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="flat-free"
                        id="flat-free"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.status.free}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="flat-free"
                      >
                        wolne
                      </label>
                    </div>
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="flat-reserved"
                        id="flat-reserved"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.status.reserved}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="flat-reserved"
                      >
                        zarezerwowane
                      </label>
                    </div>
                    <div className="offer-filter__item-check-item">
                      <input
                        type="checkbox"
                        name="flat-sold"
                        id="flat-sold"
                        className="offer-filter__item-check-item-checkbox"
                        checked={this.state.status.sold}
                        onChange={this.handleBoxChange}
                      />
                      <label
                        className="offer-filter__item-check-item-text"
                        htmlFor="flat-sold"
                      >
                        sprzedane
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="offer-filter__item offer-filter__item--slider">
                  <p className="offer-filter__item-title">
                    Powierzchnia użytkowa
                  </p>
                  <div className="offer-filter__item-slider" data-type="area">
                    <div className="offer-filter__item-slider-line">
                      <div
                        className="offer-filter__item-slider-dot offer-filter__item-slider-dot--left"
                        data-pos="left"
                        onMouseDown={this.sliderDotMoveStart}
                        onTouchStart={this.sliderDotMoveStart}
                      ></div>
                      <div className="offer-filter__item-slider-line-diff"></div>
                      <div
                        className="offer-filter__item-slider-dot offer-filter__item-slider-dot--right"
                        data-pos="right"
                        onMouseDown={this.sliderDotMoveStart}
                        onTouchStart={this.sliderDotMoveStart}
                      ></div>
                    </div>
                    <div className="offer-filter__item-slider-value offer-filter__item-slider-value--left">
                      <span>25.00</span> m<sup>2</sup>
                    </div>
                    <div className="offer-filter__item-slider-value offer-filter__item-slider-value--right">
                      <span>99.61</span> m<sup>2</sup>
                    </div>
                  </div>
                </div>
                <div className="offer-filter__item offer-filter__item--slider">
                  <p className="offer-filter__item-title">Cena</p>
                  <div className="offer-filter__item-slider" data-type="price">
                    <div className="offer-filter__item-slider-line">
                      <div
                        className="offer-filter__item-slider-dot offer-filter__item-slider-dot--left"
                        data-pos="left"
                        onMouseDown={this.sliderDotMoveStart}
                        onTouchStart={this.sliderDotMoveStart}
                      ></div>
                      <div className="offer-filter__item-slider-line-diff"></div>
                      <div
                        className="offer-filter__item-slider-dot offer-filter__item-slider-dot--right"
                        data-pos="right"
                        onMouseDown={this.sliderDotMoveStart}
                        onTouchStart={this.sliderDotMoveStart}
                      ></div>
                    </div>
                    <div className="offer-filter__item-slider-value offer-filter__item-slider-value--left">
                      <span>12 800</span> zł
                    </div>
                    <div className="offer-filter__item-slider-value offer-filter__item-slider-value--right">
                      <span>15 500</span> zł
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <button
                  className="offer__submit offer__submit--filter"
                  onClick={() =>
                    this.props.filter(this.state, this.sliderTable.amounts)
                  }
                >
                  zastosuj filtry
                </button>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default OfferFilter;
