export const GreenMap = () => {
  return (
    <>
      <section className="greenMap" id="greenMap">
        <div className="d-md-flex h-100">
          <div
            className="greenMap__image1 image-bg clickable-image"
            style={{
              backgroundImage: `url(${require("../assets/images/image5.jpg")})`,
            }}
          ></div>
          <div
            className="greenMap__image2 image-bg clickable-image"
            style={{
              backgroundImage: `url(${require("../assets/images/green.jpg")})`,
            }}
          ></div>
        </div>
      </section>
    </>
  );
};

export default GreenMap;
