// let path;
// let pathLength;

export const Location = () => {
   // useEffect(() => {
   //    path = document.querySelector("#line-path");
   //    pathLength = path.getTotalLength();
   //    path.style.strokeDasharray = pathLength + " " + pathLength;
   //    path.style.strokeDashoffset = pathLength;
   //    path.getBoundingClientRect();
   // }, []);

   // window.addEventListener("scroll", () => {
   // let scrollPercentage =
   //    (document.documentElement.scrollTop -
   //       document.documentElement.clientHeight / 2) /
   //    (document.querySelector("#location>.container").offsetTop -
   //       document.querySelector("#location>.container").offsetHeight);
   // let drawLength = pathLength * Number(scrollPercentage);
   // path.style.strokeDashoffset = pathLength - drawLength;
   // if (Number(scrollPercentage) >= 0.99) {
   //    path.style.strokeDasharray = "none";
   // } else {
   //    path.style.strokeDasharray = pathLength + " " + pathLength;
   // }
   // });

   return (
      <>
         <section className="location section--with-anim" id="location">
            <div className="container">
               <div className="row">
                  <div className="col-12 col-md-8 m-auto col-xl-4 col--small">
                     <h2>LOKALIZACJA</h2>
                  </div>
                  <div className="col-12 col-md-8 m-auto col-xl-8 col--small">
                     <div className="app__text-box">
                        <div className="line"></div>
                        <p className="app__text">
                           {/* <svg
                           id="line-svg"
                           x="0px"
                           y="0px"
                           width="10px"
                           height="100%"
                        >
                           <line
                              id="line-path"
                              fill="none"
                              stroke="#000"
                              strokeWidth="20"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="100%"
                           />
                        </svg> */}
                           Wyjątkowy adres Słowackiego 7 pozwala aktywnie
                           korzystać z przywilejów, jakie daje miasto
                           i&nbsp;najmodniejsze dzielnice Poznania. Zamieszkaj w
                           centrum by uniknąć korków lub zminimalizować koszty
                           jazdy autem. W odległości 3 minut od mieszkań
                           będziesz miał do dyspozycji komunikację miejską
                           dostępną w każdym kierunku miasta. Są&nbsp;też
                           kilometry ścieżek rowerowych, które z pewnością
                           usatysfakcjonują miłośników rowerów, czy hulajnóg.
                           A&nbsp;spacer do zoo to odległość 500&nbsp;metrów.
                           <br />
                           <span className="font-l">
                              BO W CENTRUM MIASTA JEST WSZYSTKO!
                           </span>
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Location;
