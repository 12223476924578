export const Rectangle = (props) => {
  const text = () => {
    return (
      <section
        className="rectangle"
        style={{
          backgroundImage: `url(${require("../assets/images/texture.jpg")})`,
        }}
      >
        <div id="hello"></div>
        <div className="row no-gutters h-100">
          <div className="col-3 d-none d-xl-block">
            <h2 className="rectangle__title">HELLO</h2>
          </div>
          <div className="col-12 col-xl-6 col--middle">
            <div className="rectangle__text">
              <p className="rectangle__text-primary">BIURO SPRZEDAŻY</p>
              <p className="rectangle__text-secondary">
                ul. Meissnera 4/U3 Poznań <br /> sprzedaz@slowackiego7.pl
              </p>
            </div>
          </div>
          <div className="col-3 d-none d-xl-block bg-green">
            <div className="rectangle__fb">
              <p>
                Obserwuj nas <br /> na Facebooku
              </p>
              <a href="https://www.facebook.com/Slowackiego7">
                <img
                  src={require("../assets/images/icons/fb.svg").default}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      {props.showText ? (
        text()
      ) : (
        <section
          className="rectangle"
          hidden-md="true"
          style={{
            backgroundImage: `url(${require("../assets/images/texture.jpg")})`,
          }}
        ></section>
      )}
    </>
  );
};

export default Rectangle;
