import { useRef, useEffect } from "react";

export const Investment = () => {
   const sliderRef = useRef(null);
   const dotsRef = useRef(null);

   useEffect(() => {
      sliderRef.current.addEventListener("transitionend", function () {
         sliderRef.current.classList.remove("in-transit");
      });

      const dotsRefArr = Array.prototype.slice.call(
         dotsRef.current.querySelectorAll(".slider-dots__dot")
      );

      dotsRefArr.forEach((dot) => {
         dot.addEventListener("click", function () {
            slideChange(this);
         });
      });

      resizeUpdate();
   });

   const resizeUpdate = () => {
      slideChange(0, true, sliderRef.current);

      sliderRef.current.onmousedown = dragStart;
      sliderRef.current.ontouchstart = dragStart;

      sliderRef.current.classList.remove("in-transit");
   };

   window.onresize = resizeUpdate;

   const tableSlider = {
      posInitial: null,
      posFinal: null,
      posX1: 0,
      posX2: 0,
      threshold: 15,
      target: null,
   };

   let sliderIndex = 0;

   function slideChange(e, bolean, slider) {
      if (typeof e !== "number") {
         if (e.dataset.target === "arrow" || e.dataset.target === "dot") {
            tableSlider.target = sliderRef.current;
         }
      }

      if (bolean) {
         tableSlider.target = slider;
      }

      sliderIndex = Number(tableSlider.target.dataset.index);

      if (typeof e === "number") {
         if (e === 0 && bolean) {
            sliderIndex = e;
         } else {
            sliderIndex += e;
         }
      } else {
         if (e.dataset.target === "arrow") {
            sliderIndex += Number(e.dataset.index);
         }
         if (e.dataset.target === "dot" || e.dataset.target === "slider") {
            sliderIndex = Number(e.dataset.index);
         }
      }

      let sliderIndexMax = tableSlider.target.children.length;

      const sliderDotsCurrent =
         dotsRef.current.querySelectorAll(".slider-dots__dot");

      sliderDotsCurrent.forEach((dot) => {
         dot.classList.remove("slider-dots__dot--active");

         if (
            dot.getAttribute("hidden") !== null ||
            dot.getAttribute("hidden-sm") !== null ||
            dot.getAttribute("hidden-md") !== null ||
            dot.getAttribute("hidden-xl") !== null
         ) {
            sliderIndexMax -= 1;
         }

         if (
            dot.getAttribute("hidden-md") !== null &&
            window.matchMedia("(max-width: 767px").matches
         ) {
            sliderIndexMax += 1;
         }

         if (
            dot.getAttribute("hidden-xl") !== null &&
            window.matchMedia("(max-width: 1169px").matches
         ) {
            sliderIndexMax += 1;
         }
      });

      if (sliderIndex > sliderIndexMax - 1) {
         sliderIndex = sliderIndexMax - 1;
      } else if (sliderIndex < 0) {
         sliderIndex = 0;
      }

      sliderDotsCurrent[sliderIndex].classList.add("slider-dots__dot--active");

      if (typeof e === "number") {
         tableSlider.target.dataset.index = sliderIndex;
         tableSlider.target.classList.add("in-transit");
      } else {
         if (Number(tableSlider.target.dataset.index) !== sliderIndex) {
            tableSlider.target.classList.add("in-transit");
         }
         tableSlider.target.dataset.index = sliderIndex;
      }
   }

   function dragStart(e) {
      if (e.type === "touchstart") {
         tableSlider.posX1 = e.touches[0].clientX;
      } else {
         tableSlider.posX1 = e.clientX;
      }

      tableSlider.target = sliderRef.current;

      tableSlider.posInitial = tableSlider.target.offsetLeft;

      window.onmousemove = dragMove;
      window.onmouseup = dragEnd;
      window.ontouchmove = dragMove;
      window.ontouchend = dragEnd;
   }

   function dragMove(e) {
      if (e.type === "touchmove") {
         tableSlider.posX2 = tableSlider.posX1 - e.touches[0].clientX;
      } else {
         tableSlider.posX2 = tableSlider.posX1 - e.clientX;
      }

      tableSlider.target.style.left =
         tableSlider.posInitial - tableSlider.posX2 + "px";
   }

   const dragEnd = () => {
      tableSlider.posFinal = tableSlider.target.offsetLeft;

      if (
         tableSlider.posInitial - tableSlider.posFinal >
            tableSlider.threshold &&
         sliderIndex < tableSlider.target.children.length - 1
      ) {
         slideChange(1);
      } else if (
         tableSlider.posInitial - tableSlider.posFinal <
            -tableSlider.threshold &&
         sliderIndex > 0
      ) {
         slideChange(-1);
      } else {
         slideChange(0);
      }

      tableSlider.target.removeAttribute("style");

      window.onmousemove = null;
      window.onmouseup = null;
      window.ontouchmove = null;
      window.ontouchend = null;
   };

   return (
      <>
         <section className="investment section--with-anim" id="investment">
            <div className="container">
               <div className="row position-relative">
                  <div className="col-12 col-md-8 m-auto col-xl-4 col--big">
                     <h2>Inwestycja</h2>
                  </div>
                  <div className="col-12 col-md-8 m-auto col-xl-8 col--big position-relative overflow-hidden">
                     <div className="line"></div>
                     <div className="slider">
                        <div
                           className="slider__container slider__container--investment"
                           data-index="0"
                           ref={sliderRef}
                        >
                           <div className="slider__container-item">
                              <div className="app__text-box">
                                 <p className="app__text">
                                    Obszar na którym powstaje inwestycja wchodzi
                                    w skład, zespołu
                                    urbanistycznoarchitektonicznego najstarszego
                                    przedmieścia i najstarszych dzielnic
                                    XIX-wiecznego Poznania. W skład&nbsp;tego
                                    zespołu wchodzą nie tylko willowe kamienice,
                                    ale również budynki sakralne, przemysłowe,
                                    użyteczności publicznej. Dodatkowo
                                    projektowana zabudowa jest w bezpośrednim
                                    sąsiedztwie&nbsp;współczesnych budynków
                                    przy&nbsp;ul. Roosevelta. Słowackiego 7 jest
                                    na pograniczu tych dwóch odmiennych światów
                                    architektury.
                                 </p>
                              </div>
                           </div>
                           <div className="slider__container-item">
                              <div className="app__text-box">
                                 <p className="app__text">
                                    Znajduje się jednak już zdecydowanie
                                    bardziej w „świecie” architektury
                                    współczesnej, której pas ciągnie się od
                                    ulicy Bukowskiej do ulicy Dąbrowskiego (na
                                    niezabudowanej działce na narożniku
                                    Roosevelta i ul. Dąbrowskiego planowana jest
                                    nowoczesna zabudowa wielofunkcyjna). W
                                    związku ze specyficzną lokalizacją
                                    urbanistyczną budynku nadaliśmy mu
                                    nowoczesny wyraz w poszanowaniu dla
                                    historycznej zabudowy.
                                 </p>
                              </div>
                           </div>
                        </div>

                        <div className="slider-controls">
                           <div className="slider-dots" ref={dotsRef}>
                              <span
                                 className="slider-dots__dot slider-dots__dot--active"
                                 tabIndex="0"
                                 data-index="0"
                                 data-target="dot"
                              >
                                 0
                              </span>
                              <span
                                 className="slider-dots__dot"
                                 tabIndex="0"
                                 data-index="1"
                                 data-target="dot"
                              >
                                 1
                              </span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="slider-arrows">
                     <div
                        className="slider-arrows__arrow arrow--prev"
                        data-index="-1"
                        data-target="arrow"
                        onClick={(e) => slideChange(e.target)}
                     ></div>
                     <div
                        className="slider-arrows__arrow arrow--next"
                        data-index="1"
                        data-target="arrow"
                        onClick={(e) => slideChange(e.target)}
                     ></div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Investment;
