import { NavLink } from "react-router-dom";

export const Footer = () => {
  return (
    <>
      <footer className="footer" id="footer">
        <div className="container">
          <div className="row no-gutters h-100">
            <div className="col-3 d-none d-xl-flex col--first">
              <div>
                <h3>Zadzwoń do nas</h3>
                <div className="footer__item">
                  <p className="footer__item-text">+48 723 425 562</p>
                </div>
                <div className="footer__item">
                  <p className="footer__item-text">+48 603 711 805</p>
                </div>
                {/* <div className="footer__item mb-0">
                           <p className="footer__item-text">+48 603 711 305</p>
                        </div> */}
              </div>
            </div>
            <div className="col-12 col-xl-6 col--middle">
              <h3>Godziny otwarcia</h3>
              <div className="footer__col-left">
                <div className="footer__item">
                  <p className="footer__item-text">poniedziałek - piątek</p>
                </div>
                <div className="footer__item">
                  <p className="footer__item-text">sobota</p>
                </div>
                <div className="footer__item">
                  <p className="footer__item-text">niedziela i święta</p>
                </div>
                <div className="footer__item-fb" hidden-xl={"true"}>
                  <a href="https://www.facebook.com/Slowackiego7">
                    <img
                      src={require("../assets/images/icons/fb.svg").default}
                      alt=""
                    />
                  </a>
                </div>
              </div>
              <div className="footer__col-right">
                <div className="footer__item">
                  <p className="footer__item-text">9:00 - 18:00</p>
                </div>
                <div className="footer__item">
                  <p className="footer__item-text">9:00 - 14:00*</p>
                </div>
                <div className="footer__item">
                  <p className="footer__item-text">nieczynne</p>
                </div>
                <div className="footer__item-legend">
                  <p className="footer__item-text-legend">
                    *po wcześniejszym umówieniu się na spotkanie
                  </p>
                </div>
              </div>
              <div className="footer__disclaimer">
                Prezentowane wizualizacje publikowane są w celach
                informacyjnych, mają charakter poglądowy i nie stanowią oferty
                handlowej w rozumieniu kodeksu cywilnego.
              </div>
            </div>
            <div
              className="col-3 d-none d-xl-block col--last"
              style={{
                backgroundImage: `url(${require("../assets/images/texture.jpg")})`,
              }}
            >
              <h3>Wykonanie:</h3>
              <div
                className="footer__logo"
                style={{
                  backgroundImage: `url(${
                    require("../assets/images/icons/logo-proxin.svg").default
                  })`,
                }}
              ></div>
              <div className="footer__politics">
                <NavLink to="/polityka-prywatnosci">
                  Polityka prywatności
                </NavLink>
                <br />
                <br />
                <a href="http://estatepoint.pl/">Realizacja Estate Point</a>
              </div>
            </div>
            <div className="col-12" hidden-xl={"true"}>
              <div className="footer__politics">
                <NavLink to="/polityka-prywatnosci">
                  Polityka prywatności
                </NavLink>
                <br />
                <br />
                <a href="http://estatepoint.pl/">Realizacja Estate Point</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
