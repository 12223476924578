export const Green = () => {
   return (
      <>
         <section className="green section--with-anim" id="green">
            <div className="container">
               <div className="row">
                  <div className="col-12 col-md-8 m-auto col-xl-4 col--small">
                     <h2>Zielony dziedziniec</h2>
                  </div>
                  <div className="col-12 col-md-8 m-auto col-xl-8 col--small">
                     <div className="app__text-box">
                        <div className="line"></div>
                        <p className="app__text">
                           Przemyślany projekt nasadzeń kwiatów i drzew,
                           przeplatany miejscami do siedzenia, stworzy na
                           zamkniętym dziedzińcu strefę odpoczynku i spotkań dla
                           mieszkańców.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default Green;
