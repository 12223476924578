export const Map = () => {
  return (
    <>
      <section className="map section--with-anim" id="map">
        <div className="map__box d-flex">
          <div
            className="map__box-img image-bg"
            style={{
              backgroundImage: `url(${require("../assets/images/map.png")})`,
            }}
          >
            <div className="line"></div>
          </div>
          <div
            className="map__box-legend image-bg"
            style={{
              backgroundImage: `url(${require("../assets/images/legend.png")})`,
            }}
          ></div>
        </div>
      </section>
    </>
  );
};

export default Map;
