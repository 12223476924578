export const FloorsItem = (props) => {
  const flatsCount = (status) => {
    const number = props.data.filter((item) => {
      if (status === "reservation") {
        return item.state === status || item.state === "preReservation";
      } else {
        return item.state === status;
      }
    });

    return number.length;
  };

  return (
    <>
      <div className="floors__item" data-floor={props.data[0].floor}>
        <p className="floors__item-title">
          Piętro <span>{props.data[0].floor}</span>
        </p>
        <p className="floors__item-text floors__item-free">
          Wolne
          <span>{flatsCount("available")}</span>
        </p>
        <p className="floors__item-text floors__item-reserved">
          Zarezerwowane <span>{flatsCount("reservation")}</span>
        </p>
        <p className="floors__item-text floors__item-sold">
          Sprzedane <span>{flatsCount("sold")}</span>
        </p>
      </div>
    </>
  );
};

export default FloorsItem;
